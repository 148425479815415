<template>
  <el-container class="cityD">
    <el-header style="padding: 0" height="100%">
      <vheader class="hidden-sm-and-down"></vheader>
      <ivheadera class="hidden-md-and-up"></ivheadera>
      <div class="top-img">
        <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/D-topImg.png" alt="" />
        <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/D-topImg-m.png" alt="" />
        <div class="top-img-top"><p>Start-up Policies</p></div>
        <div class="ec-top-foot">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item> <router-link to="/">首页</router-link></el-breadcrumb-item>
            <el-breadcrumb-item>深圳生活</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </el-header>
    <div class="header-nav hidden-md-and-up">
      <el-row>
        <el-col :xs="16" :sm="16" :md="16">
          <div class="sub-h-nav">
            <el-row v-show="subShow">
              <el-col :span="12" v-for="(item, index) in navTitle" v-bind:key="index">
                <div class="sub-select" @click="subSelect(index)">{{ item.txt }}</div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="8" :sm="8" :md="8">
          <div class="main-h-nav1">
            <div class="menu" @click="menuOpen">
              <i class="el-icon-arrow-right" :class="menuShow ? 'is-open' : ''"></i>
              <span>导航</span>
            </div>
            <transition name="menu-fade">
              <div class="menu-list" v-show="menuShow">
                <ul>
                  <li class="menu-item" @click="mainSelect('1')">在深创业指南</li>
                  <li class="menu-item" @click="mainSelect('2')">创业必备知识</li>
                  <li class="menu-item" @click="mainSelect('3')">税务、法务须知</li>
                  <li class="menu-item" @click="mainSelect('4')">签证选择与移民</li>
                  <li class="menu-item" @click="mainSelect('5')">公司设立指引</li>
                  <div class="back-btn" @click="menuShow = false">
                    <i class="el-icon-arrow-right"></i>
                    <span>返回</span>
                  </div>
                </ul>
              </div>
            </transition>
          </div>
          <!--            <div class="main-h-nav">-->
          <!--              <el-menu  mode="vertical" default-active="2"  @open="handleOpen" @close="handleClose" class="el-menu-vertical-demo" background-color="#000000b3"  text-color="#C8C8C8" active-text-color="#10B7D1">-->
          <!--                <el-submenu index="1">-->
          <!--                  <template slot="title">-->
          <!--                    <span>导航</span>-->
          <!--                  </template>-->
          <!--                  <div>-->
          <!--                    <el-menu-item-group index="2">-->
          <!--                      <el-menu-item  @click="mainSelect('1');">创业补贴扶持政策</el-menu-item>-->
          <!--                      <el-menu-item  @click="mainSelect('2')">创业必备知识</el-menu-item>-->
          <!--                      <el-menu-item  @click="mainSelect('3')">税务、法务须知</el-menu-item>-->
          <!--                      <el-menu-item  @click="mainSelect('4')">签证选择与移民</el-menu-item>-->
          <!--                      <el-menu-item  @click="mainSelect('5')">公司设立指引</el-menu-item>-->
          <!--                      &lt;!&ndash;                    <div>返回</div>&ndash;&gt;-->
          <!--                    </el-menu-item-group>-->
          <!--                  </div>-->
          <!--                </el-submenu>-->
          <!--              </el-menu>-->
          <!--            </div>-->
        </el-col>
      </el-row>
    </div>
    <el-main>
      <div class="w mida">
        <el-row type="flex">
          <el-col :sm="24" :md="24" :lg="24">
            <div class="mian-right">
              <div class="nav">
                <div>NAVIGATION</div>
                <div>导航</div>
              </div>
              <el-tabs class="mii" :tab-position="tabPosition" v-model="acName">
                <el-tab-pane class="cyyy" label="在深创业指南" name="1">
                  <div class="nav-title">
                    <img class="hidden-sm-and-down" width="75%" src="../../assets/images/D/SUB.png" alt />
                    <img class="hidden-md-and-up" width="55%" src="../../assets/images/D/SUB-m.png" alt />
                  </div>
                  <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="20" :lg="20">
                      <el-tabs v-model="activeName" @tab-click="handleClick" class="mian-left-l">
                        <el-tab-pane label="" name="first">
                          <el-collapse accordion>
                            <div class="tem-img" v-html="subsidies1">
                              <!-- <img class="hidden-sm-and-down" width="100%" :src="subsidies1[0]" alt="" />
                              <img class="hidden-md-and-up" width="100%" :src="subsidies1[1]" alt="" /> -->
                              <div class="cnts-item i-one">
                                <h1>深圳市海外高层次海外留学人才补贴</h1>
                                <ul>
                                  <li>
                                    <div>
                                      <i>
                                        <span>01</span>
                                        深圳市海外高层次人才概述：
                                      </i>
                                      <p>
                                        海外高层次人才是指企业技术与创新创业、科研学术与教育卫生、文化艺术与体育三个领域的国（境）外高级专家和留学回国人员，其中国（境）外高级专家分为A、B两类，留学回国人员分为A、B、C三类。A类人才对应《深圳市高层次专业人才认定办法（试行）》中的国家级领军人才、B类人才对应地方级领军人才、C类人才对应后备级人才。
                                      </p>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <i>
                                        <span>02</span>
                                        海外高层次人才确认基本条件：
                                      </i>
                                      <p>1、遵纪守法，具有良好的职业道德，严谨的科研作风和科学、求实、团结、协作的精神；</p>
                                      <p>2、外籍高级专家需与应聘单位签订一年以上的聘用合同；留学回国人员应是来深创业或已与在深单位签订聘用合同的优秀人才；</p>
                                      <p>3、满足年龄要求：</p>
                                      <p>（1）海外高层次A类人才年龄应在60周岁以下；</p>
                                      <p>（2）海外高层次B类人才年龄应在55周岁以下；</p>
                                      <p>（3）海外高层次C类人才年龄应在40周岁以下。</p>
                                      <el-row class="one-left-row" type="flex" style="flex-wrap: wrap;">
                                        <el-col :sm="24" :md="8" :lg="8">
                                          <div>
                                            <div class="left"><img src="../../assets/images/D/one-code1.png" alt="" /></div>
                                            <div class="right">
                                              <h3>A类人才（300万）</h3>
                                              <h3>申请条件：</h3>
                                              <p>
                                                诺贝尔奖获得者、世界知名奖项获得者、指定国家科学院院士或工程院院士、名企或世界知名组织负责人、国际著名专业类奖项获得者等。详情请查看二维码。
                                              </p>
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :sm="24" :md="8" :lg="8">
                                          <div>
                                            <div class="left"><img src="../../assets/images/D/one-code2.png" alt="" /></div>
                                            <div class="right">
                                              <h3>B类人才（200万）</h3>
                                              <h3>申请条件：</h3>
                                              <p>国际著名学术界领军人才、省市领军人才、世界知名高校负责人、国际著名专业类奖项获得者等。详情请查看二维码。</p>
                                            </div>
                                          </div>
                                        </el-col>
                                        <el-col :sm="24" :md="8" :lg="8">
                                          <div>
                                            <div class="left"><img src="../../assets/images/D/one-code3.png" alt="" /></div>
                                            <div class="right">
                                              <h3>C类人才（160万）</h3>
                                              <h3>申请条件：</h3>
                                              <p>担任过境外世界知名大学助理教授、海外高层次人才团队的核心成员、世界著名乐团演奏员和发明专利的主要发明人等。详情请查看二维码。</p>
                                            </div>
                                          </div>
                                        </el-col>
                                      </el-row>
                                    </div>
                                  </li>
                                </ul>
                                <h1 class="h-1">深圳前海境外高端人才和紧缺人才个人所得税财政补贴</h1>
                                <i class="i-1" style="padding: .6rem 0 .2rem 0.3rem;">资助标准：</i>
                                <el-row class="one-left-row2">
                                  <el-col :sm="24" :md="16" :lg="16" class="left">
                                    <ul>
                                      <li>财政补贴=个人所得税已纳税额-应纳税所得额*15%</li>
                                      <li>在前海缴纳个人所得税已纳税额超过应纳税所得额的15%部分由财政补贴</li>
                                      <li>财政补贴免征个人所得税。</li>
                                    </ul>
                                    <p>咨询方式：0755-88988988 (工作日8:00-12:00/13:30-19:00)</p>
                                    <p>办事地点：深圳市前海深港合作区前湾一路1号A栋前海E站通服务中心</p>
                                  </el-col>
                                  <el-col :sm="24" :md="8" :lg="8" class="right">
                                    <p style="padding-left: .1rem;">具体详情请扫描二维码：</p>
                                    <img src="../../assets/images/D/one-code4.png" alt="" />
                                  </el-col>
                                </el-row>
                              </div>
                            </div>
                          </el-collapse>
                        </el-tab-pane>
                        <el-tab-pane label="" name="second">
                          <el-col>
                            <div class="policy-centent-box" v-html="subsidies.ONE"></div>
                            <div class="policy-centent-box" v-html="subsidies.TWO"></div>
                            <div class="policy-centent-box" v-html="subsidies.THREE"></div>
                            <div class="policy-centent-box" v-html="subsidies.FOUR"></div>
                            <div class="policy-centent-box" v-html="subsidies.FIVE"></div>
                          </el-col>
                        </el-tab-pane>
                      </el-tabs>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane class="cyyy" label="创业必备知识" name="2">
                  <div class="nav-title">
                    <img class="hidden-sm-and-down" width="100%" src="../../assets/images/D/ENK.png" alt />
                    <img class="hidden-md-and-up" width="55%" src="../../assets/images/D/ENK-m.png" alt />
                  </div>
                  <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="20" :lg="20">
                      <el-tabs v-model="activeName" @tab-click="handleClick" class="mian-left-l">
                        <el-tab-pane label="" name="first">
                          <el-collapse accordion>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/01.png" alt class="enen" />
                                <img src="../../assets/images/D/concr.png" alt class="item-i" />
                                <span class="item-txt-t">涉及成立公司的词语</span>
                                <span class="item-txt-m">Noun iterpretation</span>
                              </div>
                              <div v-html="essential.ONE"></div>
                              <!--                                <div v-html="essential.TWO"></div>-->
                              <!--                                <div v-html="essential.THREE"></div>-->
                              <!--                                <div v-html="essential.FOUR"></div>-->
                            </el-collapse-item>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/02.png" alt class="enen" />
                                <img src="../../assets/images/D/director.png" alt class="item-i" />
                                <span class="item-txt-t">股东会、董事会及监事会的关系图</span>
                                <span class="item-txt-m">Relationshlp dlagram</span>
                              </div>
                              <div class="tem-img">
<!--                                <img class="hidden-sm-and-down" width="100%" src="../../assets/images/D/D2-1-02.jpg" alt="" />-->
<!--                                <img class="hidden-md-and-up" width="100%" src="../../assets/images/D/D2-1-02-m.jpg" alt="" />-->
                                <img class="hidden-sm-and-down" width="100%" :src="essential12[0]" alt="" />
                                <img class="hidden-md-and-up" width="100%" :src="essential12[1]" alt="" />
                              </div>
                            </el-collapse-item>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/03.png" alt class="enen" />
                                <img src="../../assets/images/D/defect.png" alt class="item-i" />
                                <span class="item-txt-t">公司性质优缺点对比</span>
                                <span class="item-txt-m">Advantages and disadvantages</span>
                              </div>
                              <div class="tem-img" v-html="essential.THREE">
                                <!-- <img class="hidden-sm-and-down" width="100%" :src="essential13[0]" alt="" />
                                <img class="hidden-md-and-up" width="100%" :src="essential13[1]" alt="" /> -->
                                <div class="cnts-item i-three3">
                                  <div class="cnts1">
                                    <h1>类型一：</h1>
                                    <h1>外商独资企业：</h1>
                                    <div>
                                      <p>
                                        外商独资企业指外国的公司、企业、其他经济组织或者个人，依照中国法律在中国境内设立的全部资本由外国投资者投资的企业。包括自然人独资及法人独资。
                                      </p>
                                    </div>
                                    <div>
                                      <h3>注册要求：</h3>
                                      <p>（1）租赁一个办公室作为公司注册地址；</p>
                                      <p>（2）足够的公司启动资本；</p>
                                      <p>（3）一名法定代表人（由股东自己担任即可，可以直接兼任执行董事及总经理）；</p>
                                      <p>（4）一名监事（不能是高管）；</p>
                                      <p>（5）需要1名财务负责人（如果公司人员少，可以暂由董事担任）；</p>
                                      <p>（6）需要1名有中国身份证的人作为境内法律文件送达接受人。</p>
                                    </div>
                                    <div>
                                      <h3>优点：</h3>
                                      <p>（1）股东持有100%的股份，可以独立运营，不用考虑中国投资者的因素，自主制定公司战略目标；</p>
                                      <p>（2）对比业务受限制的代表处，独资公司可以接收人民币收入以及开具发票；</p>
                                      <p>
                                        （3）没有最低注册资本金要求，股东可以根据未来公司的发展需求，拟定一个合适的注册资本金，如果后期资本金不足以运营，也可以增加注册资本，不建议将注册资本金定为1元；
                                      </p>
                                      <p>（4）注册资本不受限制，可以使用人民币、港币、美金等币种作为注册币种。</p>
                                    </div>
                                    <div>
                                      <h3>缺点：</h3>
                                      <p>（1）只能投资设立一个一人有限责任公司。</p>
                                      <p>（2）成立公司时，不能只有一个人，必须还要找一个人担任监事的职务。</p>
                                    </div>
                                    <div>
                                      <h3>举例：</h3>
                                      <p>
                                        A国人（外籍人士），看中中国的咨询行业市场，用自己的名义成立了一家咨询有限公司。半年后，公司业务发展，A国人（外籍人士）想开发一套软件做线上咨询业务，需要成立一家科技有限公司，此时，A国人（外籍人士）必须找一个B国人（外籍人士）或者B公司（外国公司），用共同的名义去申请成立合资企业，不能再只用自己一个人的名义去成立独资企业。
                                      </p>
                                    </div>
                                  </div>
                                  <div class="cnts2">
                                    <h1>类型二：</h1>
                                    <h1>中外合资企业：</h1>
                                    <div><p>依照中国有关法律在中国境内设立的外国公司、企业和其他经济组织或个人与中国公司、企业或其他经济组织共同举办的合营企业。</p></div>
                                    <div>
                                      <h3>注册要求：</h3>
                                      <p>（1）租赁一个办公室作为公司注册地址；</p>
                                      <p>（2）足够的公司启动资本；</p>
                                      <p>（3）至少一个中方投资者（企业）和一个外国投资者（企业或个人）；</p>
                                      <p>（4）需要有3名董事和1名监事；</p>
                                      <p>（5）需要1名财务负责人（如果公司人员少，可以暂由董事担任）；</p>
                                      <p>（6）需要1名有中国身份证的人作为境内法律文件送达接受人。</p>
                                    </div>
                                    <div>
                                      <h3>优点：</h3>
                                      <p>（1）资源共享，优势互补，充分利用中方企业的网络及已经建立的知名品牌，顺利进入中国市场；</p>
                                      <p>（2）合资可以使外国投资者利用中方企业的地理优势，合理、合法地减少各项财务支出，大大降低经营成本；</p>
                                      <p>（3）可以享受外商投资者优惠。</p>
                                    </div>
                                    <div>
                                      <h3>缺点：</h3>
                                      <p>（1）中方投资者不能是中国自然人，而必须是中国企业；</p>
                                      <p>（2）外国投资者的投资比例一般不低于25%；</p>
                                      <p>（3）必须成立董事会，董事会是企业最高的权利机构，法定代表人必须由董事长担任，不能由总经理担任。</p>
                                    </div>
                                    <div>
                                      <h3>举例：</h3>
                                      <p>
                                        A国人（外籍人士）跟B国人（中国国籍）是非常好的朋友，同时对中国的跨境电子商务行业非常有兴趣，就约定一起开设一家公司去运营，来到深圳市市场监督管理局，经工作人员告知，B国人（中国国籍）必须先以自己的名义开设一家内资企业，然后用中国企业的身份跟A国人（外籍人士）一起开设公司，否则不被允许注册。
                                      </p>
                                      <p>听从了工作人员建议，中外合资企业很快就开设成功，并在中国境内运营良好。</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </el-collapse-item>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/04.png" alt class="enen" />
                                <img src="../../assets/images/D/garden.png" alt class="item-i" />
                                <span class="item-txt-t">疑问解答</span>
                                <span class="item-txt-m">Question Answer</span>
                              </div>
                              <div class="tem-img" v-html="essential.FOUR">
                                <!-- <img class="hidden-sm-and-down" width="100%" :src="essential14[0]" alt="" />
                                <img class="hidden-md-and-up" width="100%" :src="essential14[1]" alt="" /> -->
                                <div class="cnts-item i-four">
                                  <ul>
                                    <li>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">Q</div>
                                        <div class="item-txt-c">外商投资企业（含外商独资企业及中外合资企业）变更股东/法定代表人有哪些办理流程及材料清单？</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div><div class="item-txt-question">A</div></div>
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/three-code1.png" alt="" />
                                            <p>扫描二维码获取详情</p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">Q</div>
                                        <div class="item-txt-c">代表处首席代表变更有哪些办理流程及材料清单？</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div><div class="item-txt-question">A</div></div>
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/three-code2.png" alt="" />
                                            <p>扫描二维码获取详情</p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">Q</div>
                                        <div class="item-txt-c">外商投资企业（含外商独资企业及中外合资企业）注销有哪些办理流程及材料清单？</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div><div class="item-txt-question">A</div></div>
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/three-code3.png" alt="" />
                                            <p>扫描二维码获取详情</p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">Q</div>
                                        <div class="item-txt-c">代表处注销有哪些办理流程及材料清单？</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div><div class="item-txt-question">A</div></div>
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/three-code4.png" alt="" />
                                            <p>扫描二维码获取详情</p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </el-collapse-item>
                          </el-collapse>
                        </el-tab-pane>
                        <el-tab-pane label="" name="second">
                          <div class="item-header" v-html="essential2.ONE"></div>
                          <el-collapse accordion>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/01.png" alt class="enen" />
                                <img src="../../assets/images/D/copy.png" alt class="item-i" />
                                <span class="item-txt-t">著作权</span>
                                <span class="item-txt-m">Copyright</span>
                              </div>
                              <!--                              <div class="tem-img">-->
                              <!--                                <img class="hidden-sm-and-down" width="100%" :src="essential21[0]" alt="" />-->
                              <!--                                <img class="hidden-md-and-up" width="100%" :src="essential21[1]" alt="" />-->
                              <!--                              </div>-->
                              <div v-html="essential2.TWO">
                                <div class="copyright-info clearfloat">
                                  <div class="copyright-info-t">
                                    <span>所属部门：国家知识产权局</span>
                                    <span>中文网址：http://www.cnipa.gov.cn</span>
                                    <span>英文网址：http://english.cnipa.gov.cn</span>
                                    <p>著作权亦称版权，是指作者对其创作的文学、艺术和科学技术作品所享有的专有权利，属于无形财产权。</p>
                                  </div>
                                  <div class="copyinfo-info-code ">
                                    <img src="../../assets/images/D/code.jpg" alt="" />
                                    <p>国家版权局官方微信</p>
                                  </div>
                                </div>
                                <div class="copy-introduce">
                                  <h1 class="copy-title">示列：计算机软件著作权的申请</h1>
                                  <div class="txt-content">
                                    <p>申请人可以自行办理计算机软件著作权登记，也可以委托代理机构办理登记。</p>
                                    <p>申请须知：</p>
                                    <div class="number-list" style="margin-top: 0"><h1>（1）计算机软件著作权受理机构：中国版权保护中心软件登记部。</h1></div>
                                    <div class="number-list">
                                      <h1>（2）办理流程：</h1>
                                      <p>网站在线填写申请表→提交申请文件→登记机构受理申请→审查→取得登记证书</p>
                                    </div>
                                    <div class="number-list"><h1>（3）受理时限：受理之日起30个工作日。</h1></div>
                                    <div class="number-list">
                                      <h1>（4）申请方式：</h1>
                                      <p>①直接到登记大厅现场办理；</p>
                                      <p>现场办理地址：北京市西城区天桥南大街1号天桥艺术大厦A座版权登记大厅（实行微信预约）</p>
                                      <p>②使用挂号信函或特快专递邮寄到中国版权保护中心软件登记部。</p>
                                      <p>邮寄联系地址：北京市西城区天桥南大街1号天桥艺术大厦A座三层302（邮编：100050）</p>
                                    </div>
                                    <div class="number-list">
                                      <h1>（5）咨询方式：</h1>
                                      <p>①软件登记咨询电话</p>
                                      <p>软件登记业务咨询：010—68003934、64097080</p>
                                      <p>办理进度查询：010—84195634</p>
                                      <p>邮寄证书查询：010—68003887—5150</p>
                                      <p>软件登记补正查询：010—84195640</p>
                                      <p>②软件档案查询及补换发证书咨询电话</p>
                                      <p>办理进度查询：010—68003887—7050</p>
                                      <p>③软件登记投诉及建议：rjdj@ccopyright.com</p>
                                    </div>
                                    <div class="number-list">
                                      <h1>（6）申请材料：</h1>
                                      <p>1.软件著作权登记申请表、；</p>
                                      <p>2.软件（程序、文档）的鉴别材料；</p>
                                      <p>3.申请人身份证明（护照复印件）、联系人身份证明和相关的证明文件。</p>
                                      <p>①代理人身份证明文件</p>
                                      <p>登记申请委托代理的，应当提交代理人的身份证明文件复印件，申请表中应当明确委托事项、委托权限范围、委托期限等内容。</p>
                                      <p>②申请人有效身份证明文件（单位的需盖公章）</p>
                                      <p>• 著作权人为外国自然人的，应提交护照复印件，及护照复印件的中文译本，并需翻译者签章。</p>
                                      <p>• 著作权人为香港企业法人的，应提交注册登记证书和有效期内的商业登记证书正本复印件，并需经中国司法部委托的香港律 师公证。</p>
                                      <p>• 著作权人为台湾企业法人的，需出示经台湾法院或公证机构认证的法人身份证明文件，填写并提交《台湾法人证明》。</p>
                                      <p>• 著作权人为外国法人及其他组织的，应提交申请人依法登记并具有法人资格的法律证明文件，该证明文件须经过中国驻当地</p>
                                      <p>
                                        领事馆的认证或经当地公证机构公证方为有效。申请时需提交公证或认证的证明文件原件。目前国外法人因所在国家或地区不
                                        同，其提交的法人身份证明文件内容和格式会有所不同，但文件中的基本信息项应至少包括：
                                      </p>
                                      <p>1.法人名称；2.注册日期；3.注册地；4.注册证明编号；5.证明文件的有效期等基本信息。</p>
                                      <p>
                                        以上身份证明文件以及与登记有关的其它证明文件（例如：合同或协议等证明）是外文的，须一并提交经有翻译资质的单位翻 译并加其公章的中文译本原件。
                                      </p>
                                      <p>③ 联系人证明文件</p>
                                      <p>
                                        申请人自行办理的，需提交联系人身份证明（身份证、护照、军官证等）复印件；委托代理人办理的，需提交联系人（申请联
                                        系人和代理联系人）身份证明复印件
                                      </p>
                                      <p>④ 权利归属的证明文件</p>
                                      <p>• 委托开发的,应当提交委托开发合同；</p>
                                      <p>• 合作开发的, 应当提交合作开发合同；</p>
                                      <p>• 下达任务开发的, 应当提交上级部门的下达任务书。</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="copy-introduce">
                                  <h1 class="copy-title">软件著作权登记流程简图</h1>
                                  <img class="flow-img" width="100%" src="../../assets/images/D/img_01.png" alt="" />
                                </div>
                                <div class="copy-introduce">
                                  <h1 class="copy-title">适用法律法规</h1>
                                  <div class="copy-statute-link">
                                    <el-col :xs="24" :sm="12" :md="12">
                                      <a href="http://www.ncac.gov.cn/chinacopyright/contents/590/21000.html">
                                        <div class="link-card left">
                                          <div class="txt-box">
                                            <p>中华人民共和国著作权法</p>
                                            <p>(2010年修正)</p>
                                          </div>
                                          <div class="link-icon"><img src="../../assets/images/D/blue-link.png" alt="" /></div>
                                        </div>
                                      </a>
                                    </el-col>
                                    <el-col :xs="24" :sm="12" :md="12">
                                      <a href="http://www.ncac.gov.cn/chinacopyright/contents/479/17536.html">
                                        <div class="link-card right">
                                          <div class="txt-box"><p>计算机软件保护条例</p></div>
                                          <div class="link-icon"><img src="../../assets/images/D/green-link.png" alt="" /></div>
                                        </div>
                                      </a>
                                    </el-col>
                                  </div>
                                </div>
                                <div class="question">
                                  <h1 class="copy-title">疑问解答</h1>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">什么是著作权(版权)？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">著作权亦称版权，是指作者对其创作的文学、艺术和科学技术作品所享有的专有权利，属于无形财产权。</div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">哪些作品受法律保护？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      <p>受保护的作品包括：</p>
                                      <p>（1）文字作品；</p>
                                      <p>（2）口述作品；</p>
                                      <p>（3）音乐、 戏剧、 曲艺、舞蹈、杂技艺术作品；</p>
                                      <p>（4）美术、建筑作品；</p>
                                      <p>（5）摄影作品；</p>
                                      <p>（6）电影作品和以类似摄制电影的方法创作的作品；</p>
                                      <p>（7）工程设计图、产品设计图、地图、示意图等图形作品和模型作品；</p>
                                      <p>（8）计算机软件；</p>
                                      <p>（9）法律、行政法规规定的其它作品。</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">保护作者著作权的法律法规主要有哪些？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      《刑法》、《民法通则》、《著作权法》、《著作权法实施条例》、《计算机软件保护条例》、《著作权集体管理条例》、《信息网络传播权保护条例》、《实施国际著作权条约的规定》以及我国加入的《世界版权公约》、《伯尔尼公约》等。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">各类作品著作权的保护期有多长？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      <p>（1）作者的署名权、修改权、保护作品完整权等人身权的保护期不受限制。</p>
                                      <p>
                                        （2）公民的作品，其发表权和著作权法规定的14项财产权的保护期为作者终生及其死亡后五十年，截止于作者死亡后第五十年的12月31日；如果是合作作品，截止于最后死亡的作者死亡后第五十年的12月31日。
                                      </p>
                                      <p>
                                        （3）法人或者其他组织的作品、著作权（署名权除外）由法人或者其他组织享有的职务作品，其发表权和著作权法规定的14项财产权的保护期为五十年，截止于作品首次发表后第五十年的12月31日，但作品自创作完成后五十年内未发表的，本法不再保护。
                                      </p>
                                      <p>
                                        （4）电影作品和以类似摄制电影的方法创作的作品、摄影作品，其发表权和著作权法规定的14项财产权的保护期为五十年，截止于作品首次发表后第五十年的12月31日，但作品自创作完成后五十年内未发表的，著作权法不再保护。
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">什么是职务作品？职务作品的著作权归谁所有？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      <p>公民为完成法人或者其他组织工作任务所创作的作品是职务作品。</p>
                                      <p>
                                        除下列情况外，著作权由作者享有，但法人或者其他组织有权在其业务范围内优先使用。作品完成两年内，未经单位同意，作者不得许可第三人以与单位使用的相同方式使用该作品。
                                      </p>
                                      <p>有下列情形之一的职务作品，作者享有署名权，著作权的其他权利由法人或者其他组织享有，法人或者其他组织可以给予作者奖励：</p>
                                      <p style="padding-left: 2em;">
                                        （1）主要是利用法人或者其他组织的物质技术条件创作，并由法人或者其他组织承担责任的工程设计图、产品设计图、 地图、计算机软件等职务作品；
                                      </p>
                                      <p style="padding-left: 2em;">（2）法律、行政法规规定或者合同约定著作权由法人或者其他组织享有的职务作品。</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">著作权纠纷应该到哪里寻求解决？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      <p>（1）和解：当事人有自行解决的意向，可以协商处理版权纠纷。</p>
                                      <p>（2）调解：当事人可以委托第三者（版权局、著作权保护机构如中华版权代理总公司、版权保护协会、律师事务所，以及自然人等）调解著作权纠纷。</p>
                                      <p>（3）仲裁：当事人可以根据达成的书面仲裁协议或者著作权合同中的仲裁条款，向仲裁机构申请仲裁。</p>
                                      <p>（4）诉讼：当事人没有书面仲裁协议，也没有在著作权合同中订立仲裁条款的，可以直接向人民法院起诉。</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">什么是计算机软件？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      计算机软件是指计算机程序及其有关文档。计算机程序是指能实现一定功能的代码化指令序列，或者符号化语句序列。文档指用来描述程序的内容、组成、设计、功能规格、开发情况、测试结果及使用方法的文字资料和图表，如程序设计说明书、流程图、用户手册等。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">游戏可以登记吗？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      一个游戏作品可以分为游戏引擎和游戏资源两大部分。游戏资源包括图象，声音，动画等部分，游戏引擎是程序代码，可以申请软件著作权登记，而游戏中动漫、视频、图片等属于其他作品，不能进行软件著作权登记。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">算法软件可以登记吗？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">算法是数学方法，在软件开发中，使用计算机程序设计语言实现的算法，其表达形式受到版权保护，可以进行登记。</div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">外国公司可以在国内进行登记吗？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">可以依据计算机软件著作权登记办法进行登记。</div>
                                  </div>
                                </div>
                              </div>
                            </el-collapse-item>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/02.png" alt class="enen" />
                                <img src="../../assets/images/D/trademark.png" alt class="item-i" />
                                <span class="item-txt-t">商标权</span>
                                <span class="item-txt-m">Trademark right</span>
                              </div>
                              <!--                              <div class="tem-img">-->
                              <!--&lt;!&ndash;                                <img class="hidden-sm-and-down" width="100%" :src="essential22[0]" alt="" />&ndash;&gt;-->
                              <!--&lt;!&ndash;                                <img class="hidden-md-and-up" width="100%" :src="essential22[1]" alt="" />&ndash;&gt;-->
                              <!--                              </div>-->
                              <div class="D2-02-02" v-html="essential2.THREE">
                                <div class="top-info-box">
                                  <p>所属部门：商标局（隶属于国家知识产权局）</p>
                                  <p>商标是产业活动中的一种识别标志，所以商标权的作用主要在于维护产业活动中的秩序。</p>
                                  <p>商标局中文网址：http://sbj.saic.gov.cn</p>
                                  <p>商标局英文网址：http://home.saic.gov.cn/sbj/sbjEnglish</p>
                                </div>
                                <div class="eg-box">
                                  <h1 class="copy-title">示例：申请注册商品商标或服务商标</h1>
                                  <div class="eg-txt-content">
                                    <div class="number-list">
                                      <h1>（1）办理途径：</h1>
                                      <h2>①申请人自行提交电子申请。</h2>
                                      <p>申请人可自行通过网上服务系统在线提交商标注册申请。</p>
                                      <p>商标网上服务系统网址：http://sbj.saic.gov.cn/wssq</p>
                                      <h2>②申请人可到以下地点办理商标注册申请。</h2>
                                      <p>a.到商标局委托地方工商和市场监管部门设立的商标受理窗口办理。</p>
                                      <p>深圳商标受理窗口</p>
                                      <p>地址：广东省深圳市福田区福中三路市民中心行政服务大厅东厅25-27号窗口</p>
                                      <p>咨询电话：0755-88127758</p>
                                      <p>b.到商标局商标注册大厅办理。</p>
                                      <p>商标局商标注册大厅办公地址：北京市西城区茶马南街1号 邮编：100055</p>
                                      <p>办公时间：8:30-11:30 13:30-16:30</p>
                                      <p>咨询电话：86-10-63218500</p>
                                      <h2>③申请人委托在商标局备案的商标代理机构办理。</h2>
                                      <h1>（2）办理流程：</h1>
                                      <h2>① 申请人直接通过网上服务系统办理的，登录网上服务系统→选择“商标注册申请”→在线填写提交申请书→在线缴纳商标 规费。</h2>
                                      <h2>② 申请人在商标注册大厅自行办理的，可以按照以下步骤办理：</h2>
                                      <p>商标注册申请前查询（非必须程序）→准备申请书件→在受理窗口提交申请书件→在打码窗口确认提交申请→在交费窗口缴 纳商标规费→领取规费收据</p>
                                      <h2>③ 委托商标代理机构办理：</h2>
                                      <p>申请人可以自愿选择任何一家在商标局备案的商标代理机构办理。</p>
                                      <h1>（3）申请前的查询（非必须程序）</h1>
                                      <p>建议在商标查询结果作出判断后再提交申请，否则如申请被驳回，会损失商标注册费及更多等待时间。</p>
                                      <h1>（4）商标注册申请手续</h1>
                                      <p>办理商标注册申请，应当提交下列文件：</p>
                                      <p>
                                        b.《商标注册申请书》可以以纸质方式或者数据电文方式提出。以纸质方式提出的，应当打字或者印刷。以数据电文方式提
                                        出的，具体要求详见中国商标网“网上申请”栏目相关规定。
                                      </p>
                                      <p>c.申请人为法人或其他组织的，应当在申请书的指定位置加盖公章；申请人为自然人的，应当由申请人使用钢笔或签字笔在 指定位置签字确认。</p>
                                      <p>d.台湾地区申请人办理商标注册申请并要求台湾地区优先权时，应当适用台湾地区申请人专用的《商标注册申请书》。</p>
                                      <p>e.《商标注册申请书》填写要求详见《商标注册申请书》所附填写说明。</p>
                                      <h2>② 申请人身份证明文件复印件（同一申请人同时办理多件商标的注册申请事宜时，只需要提供一份）</h2>
                                      <p>
                                        a.申请人为港澳台或国外的法人或其他组织的，应当提交所属地区或国家的登记证件复印件。外国企业在华的办事处、常驻
                                        代表机构的登记证复印件不能作为身份证明文件复印件。上述文件是外文的，应当附送中文译文；未附送的，视为未提交该 文件。
                                      </p>
                                      <p>
                                        b.申请人为港澳台自然人且自行办理的，应当提交在有效期（一年以上）内的《港澳居民来往内地通行证》、《台湾居民来往
                                        大陆通行证》或《港澳台居民居住证》复印件；申请人为国外自然人且自行办理的，应当提交护照复印件及公安部门颁发的、
                                        在有效期（一年以上）内的《外国人永久居留证》、《外国人居留许可》或《外国人居留证》。
                                      </p>
                                      <h2>③ 商标图样</h2>
                                      <p>a.每一件商标注册申请应当提交1份商标图样。以颜色组合或者着色图样申请商标注册的，应当提交着色图样；不指定颜色的， 应当提交黑白图样。</p>
                                      <p>
                                        b.商标图样必须清晰，便于粘贴，用光洁耐用的纸张印制或者用照片代替，长和宽应当不大于10厘米，不小于5厘米。商标
                                        图样应粘贴在《商标注册申请书》指定位置。
                                      </p>
                                      <p>
                                        c.以三维标志申请商标注册的，应当在申请书中予以声明，并应在《商标注册申请书》“商标说明”栏中说明商标的使用方式。
                                        申请人应当提交能够确定三维形状的图样，该商标图样应至少包含三面视图。
                                      </p>
                                      <p>
                                        d.以颜色组合申请商标注册的，应当在申请书中予以声明，并在《商标注册申请书》“商标说明”栏中加以文字说明，说明
                                        色标和商标的使用方式。商标图样应当是表示颜色组合方式的色块，或是表示颜色使用位置的图形轮廓。该图形轮廓不是商
                                        标构成要素，必须以虚线表示，不得以实线表示。
                                      </p>
                                      <p>
                                        e.以声音标志申请注册商标的，应当在申请书中予以声明，并在商标图样框里对声音商标进行描述，同时报送符合要求的声音
                                        样本，以及在《商标注册申请书》“商标说明”栏中说明商标的使用方式。
                                      </p>
                                      <p class="padding-left-2em">
                                        (a)声音商标的描述。应当以五线谱或者简谱对申请用作商标的声音加以描述并附加文字说明；无法以五线谱或者简谱描述 的，应当使用文字进行描述。
                                      </p>
                                      <p class="padding-left-2em">(b)声音样本的要求：</p>
                                      <p>① 通过纸质方式提交声音商标注册申请的，声音样本的音频文件应当储存在只读光盘中，且该光盘内应当只有一个音频文 件。</p>
                                      <p>通过数据电文方式提交声音商标注册申请的，应按照要求正确上传声音样本。</p>
                                      <p>② 声音样本的音频文件应小于5MB，格式为wav或mp3。</p>
                                      <p>③ 商标描述与声音样本应当一致。</p>
                                      <h1>（5）申请人提交的各种证件、证明文件和证据材料是外文的，应当附送中文译文。未附送的，视为未提交该证件、证明文件 或者证据材料。</h1>
                                      <h1>（6）商标注册申请补正程序（非必经程序）</h1>
                                      <p>申请手续不齐全或申请文件不符合规定，被要求补正资料的，限自收到通知之日起30日内，按照指定内容补正并交回商标局。</p>
                                      <h1>（7）商标注册申请的实质审查程序</h1>
                                      <p>
                                        商标局对受理的商标注册申请，依照商标法的有关规定进行审查，对符合规定的，予以初步审定，并予以公告；对不符合规
                                        定的，予以驳回，书面通知申请人并说明理由。
                                      </p>
                                      <h1>（8）注意事项</h1>
                                      <h2>（一）《注册申请受理通知书》仅表明商标注册申请已被商标局受理，并不表明该申请已被核准。</h2>
                                      <h2>（二）商标注册申请被驳回的，如果对驳回决定不服，申请人可以自收到驳回通知之日起15日内向商标评审委员会申请复审。</h2>
                                      <h2>
                                        （三）申请注册的商标被提出异议的，如果申请人（即被异议人）对商标局的不予注册决定不服，可以自收到通知之日起15 日内向商标评审委员会申请复审。
                                      </h2>
                                      <h2>
                                        （四）商标在提出申请之后但尚未核准注册前仍为未注册商标，仍须按未注册商标使用。如果使用该商标侵犯他人商标专用权，
                                        不影响有关工商行政管理机关对该行为的查处。
                                      </h2>
                                      <h2>
                                        （五）注册商标的有效期为10年，自核准注册之日起计算。注册商标有效期满需要继续使用的，商标注册人应当在期满前12
                                        个月内办理续展手续。商标注册人在此期间未能办理的，可以在期满后的6个月的宽展期内提出，但须缴纳受理续展注册迟延
                                        费。宽展期满后仍未提出续展申请的，商标局将注销该注册商标，如果原注册人想继续拥有该商标专用权，则须重新提出注 册申请。
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                                <div class="flowChart-box">
                                  <h1 class="copy-title">商标注册流程简图</h1>
                                  <img class="box-img" width="100%" src="../../assets/images/D/D2-2-2-img01.jpg" alt="" />
                                </div>
                                <div class="question">
                                  <h1 class="copy-title">疑问解答</h1>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">外国人和外国公司，可以自行办理商标注册申请吗？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      外国人或者外国企业在中国申请商标注册和办理其他商标事宜的，应当委托依法设立的商标代理机构办理。但在中国有经常居所或者营业所的外国人或外国企业，可以自行办理。通过以下网址可以查询经国家知识产权局商标局备案的商标代理机构：http://wssq.saic.gov.cn:9080/tmsve/agentInfo_getAgentDljg.xhtml
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">我们是一家公司在深圳的代表处，可以申请商标注册吗？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">代表处、办事处不能以自己的名义申请商标注册。</div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">自行办理与委托商标代理机构办理有什么区别吗？哪种方式更快些？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      不论采用哪种方式，商标注册申请都需要经过形式审查及实质审查，审查要求也是一样的。在委托商标代理机构的方式下，需跟商标代理机构签署办理商标注册事宜的授权委托书，申请商标注册文件亦由商标代理机构直接向商标局提交，而申请人不会直接与商标局发生联系。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">什么是尼斯分类？什么是《类似商品和服务区分表》？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      《商标注册用商品和服务国际分类》（尼斯分类）是根据1957年6月15日由尼斯外交会议达成的一项协定（尼斯协定）制定的。尼斯协定的每个成员国有义务在商标注册中使用尼斯分类，并须在与商标注册有关的官方文件和出版物中标明注册商标所及的商品或服务所在的国际分类的类别号。我国于1994年加入尼斯协定。
                                      《类似商品和服务区分表》是商标主管部门为了商标检索、审查、管理工作的需要，总结多年来的实践工作经验，并广泛征求各部门的意见，把某些存在特定联系、容易造成误认的商品或服务组合到一起，编制而成。《类似商品和服务区分表》可以作为商标审查人员、商标代理人和商标注册申请人判断类似商品或者服务的参考，也可以作为行政机关和司法机关在处理商标案件时判断类似商品或者服务的参考。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">关于商品的分类原则都有哪些？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      《商标注册用商品和服务国际分类》（尼斯分类）中给出了商品和服务大致的分类原则。申请人可以查阅。一般来说，类别标题中所列的商品或服务名称原则上构成这些商品或服务大致所属范围的一般性名称。所以要确定每一种商品或服务的分类，就得查看按字母顺序排列的分类表。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">关于服务的分类原则都有哪些？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      《商标注册用商品和服务国际分类》（尼斯分类）中给出了商品和服务大致的分类原则。申请人可以查阅。一般来说，类别标题中所列的商品或服务名称原则上构成这些商品或服务大致所属范围的一般性名称。所以要确定每一种商品或服务的分类，就得查看按字母顺序排列的分类表。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">拿到受理通知书后是否就可以使用商标了？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      受理通知书仅表明商标注册申请已被商标局受理，并不表明所申请商标已获准注册。商标在提出申请之后但尚未核准注册前仍为未注册商标，仍须按未注册商标使用。如果使用该商标侵犯他人商标专用权，不影响有关工商行政管理机关对该行为的查处。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">不予受理的情形有哪些？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      <p>一般来说，商标注册申请不予受理的主要情形包括（但不限于）以下几种：</p>
                                      <p>· 未按要求使用正确申请书式的；擅自修改申请书格式的。</p>
                                      <p>· 申请书未使用中文的；提交的各种证件、证明文件和证据材料是外文的，未附送中文翻译文件并加盖申请人、代理机构或翻译公司公章的。</p>
                                      <p>· 申请书上申请人名称、所盖章戳或签字、所附身份证明文件复印件不一致的。</p>
                                      <p>· 商标图样不符合商标法实施条例第十三条规定的。</p>
                                      <p>· 境外公证未同时附送认证文件的。</p>
                                      <p>· 未向商标局缴纳规费的。</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </el-collapse-item>
                            <el-collapse-item>
                              <div slot="title" class="item-txt">
                                <img src="../../assets/images/D/03.png" alt class="enen" />
                                <img src="../../assets/images/D/patent.png" alt class="item-i" />
                                <span class="item-txt-t">专利权</span>
                                <span class="item-txt-m">Patent right</span>
                              </div>
                              <div class="D2-02-02" v-html="essential2.FOUR">
                                <div class="top-info-box">
                                  <p>含发明、实用新型和外观设计专利</p>
                                  <p>所属部门：国家知识产权局</p>
                                  <p>中文网址：http://www.sipo.gov.cn/zhfwpt/zlsqzn_pt/zlfssxzjsczn/index.htm</p>
                                  <p>英文网址：http://english.cnipa.gov.cn/lawpolicy/index.htm</p>
                                </div>
                                <div class="eg-box">
                                  <h1 class="copy-title">示例：专利申请</h1>
                                  <div class="eg-txt-content">
                                    <div class="number-list">
                                      <h1>（1）申请途径</h1>
                                      <h2>①以电子形式申请，需事先办理电子申请用户注册手续，通过专利局专利电子申请系统向专利局提交申请文件及其他文件。</h2>
                                      <h2>②以书面形式申请。</h2>
                                      <p>
                                        申请文件及其他文件当面交到专利局的受理窗口或寄交至“国家知识产权局专利局受理处”（以下简称专利局受理处），也可以
                                        当面交到设在地方的专利局代办处的受理窗口或寄交至“国家知识产权局专利局深圳代办处”。
                                      </p>
                                      <p>国家知识产权局专利局受理处</p>
                                      <p>总机：010-62083114</p>
                                      <p>客户服务中心：010-62085588/5599、010-62356655</p>
                                      <p>专利复审受理咨询：010-62801799</p>
                                      <p>专利事务服务咨询电话：010-62086383</p>
                                      <p>专利事务服务咨询电子邮箱：cpquery@sipo.gov.cn</p>
                                      <p>专利文献咨询：010-62083246/3238</p>
                                      <p>地址：北京市海淀区蓟门桥西土城路6号</p>
                                      <p>国家知识产权局专利局深圳代办处</p>
                                      <p>电话：0755-26617303</p>
                                      <p>传真：0755-26617304</p>
                                      <p>邮编：518057</p>
                                      <p>地址：广东省深圳市南山区高新区南区学府路深圳软件产业基地4栋C座六楼</p>
                                      <p>网址：www.szipr.org</p>
                                      <p>开户银行：中国光大银行深圳高新技术园支行</p>
                                      <p>户名：深圳国家知识产权局专利代办处</p>
                                      <p>账号：39020188000058725</p>
                                      <h1>（2）申请专利应当提交哪些申请文件？</h1>
                                      <h2>
                                        ①申请发明专利的，申请文件应当包括：发明专利请求书、说明书摘要（必要时应当提交摘要附图）、权利要求书、说明书 （必要时应当提交说明书附图）。
                                      </h2>
                                      <h2>②申请实用新型专利的，申请文件应当包括：实用新型专利请求书、说明书摘要及其摘要附图、权利要求书、说明书、说明 书附图。</h2>
                                      <h2>
                                        ③申请外观设计专利的，申请文件应当包括：外观设计专利请求书、图片或者照片（要求保护色彩的，应当提交彩色图片或
                                        者照片）以及对该外观设计的简要说明。
                                      </h2>
                                      <h1>
                                        <span>（3）申请文件使用统一制定的表格</span>
                                        <p style="padding-left: 0.76rem;">申请文件应当使用专利局统一制定的表格。</p>
                                      </h1>
                                      <p>获取表格方式：</p>
                                      <p>1.国家知识产权局网站下载，下载地址http://www.cnipa.gov.cn/bgxz/；</p>
                                      <p>2.国家知识产权局专利局受理大厅的咨询处索取；</p>
                                      <p>3.国家知识产权局专利局深圳代办处索取。</p>
                                      <p>
                                        申请文件的纸张质量应当相当于复印机用纸的质量。纸面不得有无用的文字、记号、框、线等。各种文件一律采用A4尺寸
                                        （210毫米×297毫米）的纸张。申请文件的纸张应当单面、纵向使用。文字应当自左向右排列，纸张左边和上边应当各留
                                        25毫米空白，右边和下边应当各留15毫米空白。
                                      </p>
                                      <h1>（4）提交申请时如何排列申请文件</h1>
                                      <p>
                                        发明或者实用新型专利申请文件（按照下列顺序排列）：请求书、说明书摘要、摘要附图、权利要求书、说明书（含氨基酸或核 苷酸序列表）、说明书附图。
                                      </p>
                                      <p>外观设计专利申请文件（按照下列顺序排列）：请求书、图片或照片、简要说明。申请文件各部分都应当分别用阿拉伯数字顺序 编写页码。</p>
                                      <h1>（5）申请文件的文字和书写要求</h1>
                                      <p>申请文件各部分一律使用中文。外国人名、地名和科技术语如没有统一中文译文，应当在中文译文后的括号内注明原文。</p>
                                      <p>申请文件都应当用宋体、仿宋体或楷体打字或印刷，字迹呈黑色，字高应当在3.5～4.5毫米之间，行距应当在2.5～3.5毫米之间。</p>
                                      <p>申请文件中有附图的，线条应当均匀清晰，不得涂改。不得使用工程蓝图作为附图。</p>
                                      <h1>（6）证明文件</h1>
                                      <p>
                                        办理专利申请相关手续要附具证明文件的，各种证明文件应当由有关主管部门出具或者由当事人签署。各种证明文件应当是原
                                        件；证明文件是复印件的，应当经公证或者由出具证明文件的主管部门加盖公章予以确认（原件在专利局备案确认的除外）。申
                                        请人提供的证明文件是外文的，应当附有中文题录译文。
                                      </p>
                                      <h1>（7）签字或者盖章</h1>
                                      <p>
                                        向专利局提交的专利申请文件或者其他文件，应当按照规定签字或者盖章。其中未委托专利代理机构的申请，应当由申请人（或
                                        专利权人）、其他利害关系人或者其代表人签字或者盖章，办理直接涉及共有权利的手续，应当由全体权利人签字或者盖章；委
                                        托了专利代理机构的，应当由专利代理机构盖章，必要时还应当由申请人（或专利权人）、其他利害关系人或者其代表人签字或 者盖章。
                                      </p>
                                      <h1>（8）同日申请说明</h1>
                                      <p>同一申请人同日对同样的发明创造既申请实用新型专利又申请发明专利的，应当在申请时分别说明。</p>
                                      <h1>（9）专利申请内容的单一性要求</h1>
                                      <p>一件发明或者实用新型专利申请应当限于一项发明或者实用新型。</p>
                                      <h1>（10）委托专利代理机构</h1>
                                      <p>
                                        在中国内地没有经常居所或者营业所的外国人、外国企业或者外国其他组织在中国申请专利，或者作为第一署名申请人与中国内
                                        地的申请人共同申请专利的，应当委托依法设立的专利代理机构办理。
                                      </p>
                                      <h1>（11）专利申请的受理</h1>
                                      <p>
                                        专利局受理处或专利局代办处收到专利申请后，对符合受理条件的申请，将确定申请日，给予申请号，发出受理通知书。
                                        向专利局受理处寄交申请文件的，一般在一个月左右可以收到专利局的受理通知书，超过一个月尚未收到专利局通知的，申请人
                                        应当及时向专利局受理处查询。
                                      </p>
                                      <h1>（12）申请日的确定</h1>
                                      <p>
                                        采用电子文件形式向专利局提交的专利申请及各种文件，以专利局专利电子申请系统收到电子文件之日为递交日。
                                        向专利局受理处或者代办处窗口直接递交的专利申请，以收到日为申请日；通过邮局邮寄递交到专利局受理处或者代办处的专利
                                        申请，以信封上的寄出邮戳日为申请日。
                                      </p>
                                      <h1>（13）申请费的缴纳时间</h1>
                                      <p>申请人应当自申请日起两个月内或在收到受理通知书之日起15日内缴纳申请费。缴纳申请费需写明相应的申请号及必要的缴费 信息。</p>
                                      <h1>（14）如何缴纳费用</h1>
                                      <h2>①电子申请用户可以登录电子申请网（http://cponline.cnipa.gov.cn/），使用网上缴费方式缴纳专利费用。</h2>
                                      <h2>②直接向专利局或专利局代办处缴纳专利费用。</h2>
                                      <h2>③通过银行或邮局汇付专利费用。通过银行或邮局汇付专利费用时，应当在汇款单附言栏中写明正确的申请号（或专利号） 及费用名称（或简称）。</h2>
                                      <p>银行汇付：</p>
                                      <p>开户银行：中信银行北京知春路支行</p>
                                      <p>户 名：中华人民共和国国家知识产权局专利局</p>
                                      <p>帐 号：7111710182600166032</p>
                                      <p>邮局汇付：</p>
                                      <p>收款人姓名：国家知识产权局专利局收费处</p>
                                      <p>商户客户号：110000860（可代替地址邮编）</p>
                                      <p>地址邮编：北京市海淀区蓟门桥西土城路6号（100088）</p>
                                      <h1>（15）向外国申请专利前的保密审查</h1>
                                      <p>
                                        任何单位或者个人将在中国完成的发明或者实用新型向外国申请专利或者向有关国外机构提交专利国际申请前，应当向专利局提
                                        出向外国申请专利保密审查请求。经保密审查确定涉及国家安全或者重大利益需要保密的，任何单位或者个人不得就该发明或者
                                        实用新型的内容向外国申请专利。
                                      </p>
                                      <h2>提出向外国申请专利前的保密审查请求有下列三种方式：</h2>
                                      <h2>
                                        ①以技术方案形式单独提出保密审查请求。以该种方式提出请求的，申请人应当提交向外国申请专利保密审查请求书和技术
                                        方案说明书，并采用书面形式将文件当面交到专利局的受理窗口或寄交至“国家知识产权局专利局受理处”。
                                      </h2>
                                      <h2>②申请中国专利的同时或之后提出保密审查请求。以该种方式提出请求的，申请人应当提交向外国申请专利保密审查请求书。</h2>
                                      <h2>
                                        ③向专利局提交专利国际申请的，视为同时提出了保密审查请求，不需要单独提交向外国申请专利保密审查请求书。
                                        《向外国申请专利保密审查事项服务指南》参见http://www.cnipa.gov.cn/docs/20180206152953119132.pdf
                                      </h2>
                                      <h1>（16） 提交申请文件注意事项</h1>
                                      <h2>①向专利局提交的各种文件申请人都应当留存底稿，以保证申请审批过程中文件填写的一致性，并可以此作为答复审查意见 时的参照。</h2>
                                      <h2>
                                        ②申请文件是邮寄的，应当用挂号信函。无法用挂号信邮寄的，可以用特快专递邮寄，不得用包裹邮寄申请文件。挂号信函
                                        上除写明专利局或者专利局代办处的详细地址（包括邮政编码）外，还应当标有“申请文件”及“国家知识产权局专利局受
                                        理处收”或“国家知识产权局专利局××代办处收”的字样。申请文件通过快递公司递交的，以专利局受理处以及各专利局
                                        代办处实际收到日为申请日。一封挂号信内应当只装同一件申请的文件。邮寄后，申请人应当妥善保管好挂号收据存根。
                                      </h2>
                                      <h2>
                                        ③专利局在受理专利申请时不接收样品、样本或模型。在审查程序中，申请人应审查员要求提交样品或模型时，若在专利局
                                        受理窗口当面提交的，应当出示审查意见通知书；邮寄的应当在邮件上写明“应审查员×××（姓名）要求提交模型”的字样。
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                                <div class="flowChart-box">
                                  <h1 class="copy-title">专利申请流程图</h1>
                                  <img width="100%" src="../../assets/images/D/D2-2-3-img01.jpg" alt="" class="box-img" />
                                </div>
                                <div class="question">
                                  <h1 class="copy-title">疑问解答</h1>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">什么是发明专利?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">专利法所称发明是指对产品、方法或者其改进所提出的新的技术方案。</div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">什么是外观设计专利?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      <p>外观设计是指产品外观的设计方案。它与发明或实用新型完全不同，即外观设计不是技术方案。外观设计专利应当符合以下要求：</p>
                                      <p>（1）是指形状、图案或者其结合以及色彩与形状、图案的结合的设计；</p>
                                      <p>（2）必须是对产品的外观所作的设计；</p>
                                      <p>（3）必须富有美感；</p>
                                      <p>（4）必须是适于工业上的应用。</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">什么叫实用新型?哪些发明创造可以申请实用新型专利?</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      《专利法》所称实用新型是指对产品的形状、构造或者其结合所提出的适于实用的新的技术方案。实用新型与发明的不同之处在于：第一，实用新型限于具有一定形状的产品，不能是一种方法，也不能是没有固定形状的产品；第二，对实用新型的创造性要求相比发明较低。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">申请了专利，还没有批准前，在产品包装上将申请号印刷宣传成专利号，是不是违法？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">
                                      违法。《专利法》第十七条规定，专利权人有权在其专利产品或者该产品的包装上标明专利标记和专利号。需要指出的是，依照本条的规定，在专利申请提出以后，授权以前，由于尚未获得专利权，申请人不得将该申请号作为专利号标注在其产品或包装上。
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="item-txt-a">
                                    <div class="item-txt-b">Q</div>
                                    <div class="item-txt-c">申请人（个人）与发明人或设计人可否为不同的人？</div>
                                  </div>
                                  <div class="item-txt-box clearfloat">
                                    <div class="item-txt-question">A</div>
                                    <div class="item-txt-answer">申请人（个人）与发明人或设计人可以为不同的人。</div>
                                  </div>
                                </div>
                              </div>
                              <!--                              <div class="tem-img">-->
                              <!--                                <img class="hidden-sm-and-down" width="100%" :src="essential23[0]" alt="" />-->
                              <!--                                <img class="hidden-md-and-up" width="100%" :src="essential23[1]" alt="" />-->
                              <!--                              </div>-->
                            </el-collapse-item>
                          </el-collapse>
                        </el-tab-pane>
                      </el-tabs>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane class="cyyy" label="税务、法务须知" name="3">
                  <div class="nav-title">
                    <img class="hidden-sm-and-down" width="60%" src="../../assets/images/D/TL.png" alt="" />
                    <img class="hidden-md-and-up" width="55%" src="../../assets/images/D/TL-m.png" alt="" />
                  </div>
                  <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="20" :lg="20">
                      <el-collapse class="mian-left-l">
                        <div class="title-box">
                          <div>
                            <div class="text">主管单位：</div>
                            <div class="text">国家税务总局深圳市税务局</div>
                          </div>
                        </div>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/01.png" alt class="enen" />
                            <img src="../../assets/images/D/taxes.png" alt class="item-i" />
                            <span class="item-txt-t">常见的税种及税率</span>
                            <span class="item-txt-m">Taxes and tax rates</span>
                          </div>
                          <div class="tab" v-html="taxLaw.ONE"></div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/02.png" alt class="enen" />
                            <img src="../../assets/images/D/concr.png" alt class="item-i" />
                            <span class="item-txt-t">税收知识</span>
                            <span class="item-txt-m">Tax knowledge</span>
                          </div>
                          <div class="tem-img" v-html="taxLaw.TWO">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="taxLaw2[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="taxLaw2[1]" alt="" /> -->
                            <!-- <div class="cnts-item i-five">
                              <div>
                                <h1>纳税年度 ：</h1>
                                <p>公历1月1日至12月31日</p>
                              </div>
                              <div>
                                <h1>税收缴款方式：</h1>
                                <p>1.通过税务局和企业银行账户关联，直接从企业银行账户中扣取；</p>
                                <p>2.通过微信扫码支付方式缴纳。</p>
                              </div>
                              <div>
                                <h1>报税时间安排：</h1>
                                <p>1.每月15日前需要申报上个月的个人所得税，一般情况下，工资是按月发放的；</p>
                                <p>2.每年的4月15日、7月15日、10月15日、1月15日前，需要向税务局申报上个季度的增值税、企业所得税等；</p>
                                <p>3.每年的5月31日前需要把年度的财务报表/审计报告做好,并向税务局进行申报。</p>
                              </div>
                              <div><h1>税务咨询电话：12366</h1></div>
                              <div class="cnt2">
                                <h1>Tips:</h1>
                                <p>
                                  1.法定代表人/财务负责人/办税人员需到税务局办理实名认证登记，并需绑定大陆手机号码。否则，将不能办理报税事宜。时间：在申请营业执照、刻制公章之后。
                                </p>
                                <p>2.规范使用发票。在销售货物的同时，需向购买方开具发票，如未按实开具，可能被税务局认定有偷税漏税嫌疑，面临处罚。</p>
                                <p>3.公司的营业收入必须通过公司的银行基本户收取，不得从法定代表人的私人银行账户收取。</p>
                                <p>4.因公司业务出差坐车、外出就餐时，需要向司机及商家索取发票。发票是证明此次出差业务的合法凭证。</p>
                              </div>
                              <div>
                                <h1>疑问解答</h1>
                                <ul>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">注册资本和实收资本有什么区别？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          注册资本是公司在设立时筹集的、由章程载明的、经公司登记机关登记注册的资本，是股东认缴或认购的出资额。实收资本是公司成立时实际收到的股东的出资总额，是公司现实拥有的资本。由于公司认购股份以后，可以一次全部缴清，也可以分期缴纳，所以实收资本在某段时间内可能小于注册资本，但公司的注册资本与实收资本最终是应当一致的。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">实收资本到位是否要缴纳印花税吗？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          需要，按实收资本具体金额缴纳，税率为万分之五。实收资本是公司成立时实际收到的股东的出资总额，是公司现实拥有的资本。由于公司认购股份以后，可以一次全部缴清，也可以分期缴纳，所以实收资本在某段时间内可能小于注册资本，但公司的注册资本与实收资本最终是应当一致的。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">公司购买了办公室需要到税务局登记备案吗？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">需要，在发生的30天内到所属税务机关办理税务登记手续。登记完成后需要缴纳房产税，如逾期，将被罚款及加收滞纳金。</div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">发票遗失了，有什么影响？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer"><div class="cnts">发票遗失了，会被罚款，具体金额以税务局为准。</div></div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">深圳税务局已实行网络购票，那网络购票地址是否可以填写深圳以外的地址？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          不可以，发票管理办法不允许跨区域运输和使用发票。因此，网络购票不能向深圳地区以外的地址投递，纳税人也不能携带空白发票到异地使用。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div> -->
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/03.png" alt class="enen" />
                            <img src="../../assets/images/D/legal.png" alt class="item-i" />
                            <span class="item-txt-t">法务须知</span>
                            <span class="item-txt-m">Legal Notice</span>
                          </div>
                          <div class="tem-img" v-html="taxLaw.THREE">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="taxLaw3[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="taxLaw3[1]" alt="" /> -->
                            <!-- <div class="cnts-item i-six">
                              <div>
                                <h1>
                                  税务、财务风险
                                  <span>（主管单位：国家税务总局深圳市税务局 ）</span>
                                </h1>
                                <p>
                                  因为涉及2个不同国家或地区的税务、财务规定。中国的税收制度和国外的有差别，例如，中国的税收有按月度、季度和年度申报的，一旦逾期不申报，将被拉入黑名单，并需要缴纳罚款及滞纳金。
                                </p>
                              </div>
                              <div>
                                <h1>
                                  用人风险
                                  <span>（主管单位：深圳市人力资源和社会保障局）</span>
                                </h1>
                                <p>
                                  如果聘请的是中国人，需要了解国内的劳动法。如果聘请的是外国人，需要注意及时办理工作签证等相关手续。招聘员工时，应因特别留意核心员工入职登记、法律审查，劳动合同、保密协议、竞业限制条款设定以及需要为员工购买社会保险及公积金。
                                </p>
                              </div>
                              <div>
                                <h1>
                                  经营风险
                                  <span>（主管单位：中华人民共和国商务部）</span>
                                </h1>
                                <p>外商投资者在中国经营，必须符合《外商投资准入特别管理 措施（负面清单）及《市场准入负面清单》；如果超出范围经营，会受到政府查处及相应的处罚。</p>
                              </div>
                              <div class="cnt2">
                                <div class="left"><img src="../../assets/images/D/six-code1.png" alt="" /></div>
                                <div class="right">
                                  <p>发展改革委 商务部令2018年第19号</p>
                                  <p>自由贸易试验区外商投资准入特别管理措施（负面清单）（2018年版）</p>
                                </div>
                              </div>
                              <div>
                                <h1>疑问解答</h1>
                                <ul>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">企业成立的次年6月30日前必须完成企业年报公示，不进行公示对企业是否有影响？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          未按规定期限公示年度报告的企业，工商机关会将其载入经营异常名录，并处罚款。超过3年未履行的，工商机关将其永久列入严重违法企业“黑名单”。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">除了每年的年报公示，在税务局系统上同样需要进行汇算清缴，不按时申报对企业的影响是？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          在企业成立的次年5月31日前需要进行税务局的汇算清缴，未在规定期限内按时做汇算清缴，或提交材料不齐全的企业，主管的税务机关可进行处罚及加收滞纳金。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">是否必须出具审计报告？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          企业根据自身需要出具即可。如有招投标等第三方要求必须出具、为了让不参与实际运营管理的投资人对企业更放心，也为了更管理层更了解企业财务情况，可以出具审计报告。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">如何为公司员工购买社会保险？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          <p>1.在领取开户许可证后，向深圳市人力资源和社会保障局申请办理公司社会保险账号；</p>
                                          <p>2.携带劳动合同等资料前往所属社保机构办理。具体材料清单可咨询：12333。</p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div class="item-txt-b">Q</div>
                                      <div class="item-txt-c">深圳税务局已实行网络购票，那网络购票地址是否可以填写深圳以外的地址？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div class="item-txt-question">A</div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          不可以，发票管理办法不允许跨区域运输和使用发票。因此，网络购票不能向深圳地区以外的地址投递，纳税人也不能携带空白发票到异地使用。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div> -->
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/04.png" alt class="enen" />
                            <img src="../../assets/images/D/suggest.png" alt class="item-i" />
                            <span class="item-txt-t">财务、法务相关建议</span>
                            <span class="item-txt-m">Financial and legal suggestions</span>
                          </div>
                          <div class="tem-img" v-html="taxLaw.FOUR">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="taxLaw4[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="taxLaw4[1]" alt="" /> -->
                            <div class="cnts-item i-seven">
                              <h1>业务外包与聘请团队的优劣势</h1>
                              <div class="tab">
                                <el-row>
                                  <el-col :span="6"><div class="tab-header-td">方式</div></el-col>
                                  <el-col :span="6"><div class="tab-header-td">建议</div></el-col>
                                  <el-col :span="12"><div class="tab-header-td">优劣势</div></el-col>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>财税/法律业务外包</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>发展初期团队成员少，对中国市场了解少，建议考虑财税/法律业务外包。</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <div class="left">优势：</div>
                                          <div class="right">
                                            <p>
                                              (1)专业度高：财税代理公司专业度高，能够按时进行申报税务，能大大降低逾期申报造成罚款，影响企业信用；也可以获得法律业务服务机构优秀法律团队的优质服务。
                                            </p>
                                            <p>(2)降低运营成本。</p>
                                          </div>
                                        </div>
                                        <div class="box">
                                          <div class="left">劣势：</div>
                                          <div class="right"><p>商业信息泄露风险、沟通效率风险。</p></div>
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>聘请财务/法律团队</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>聘请财务/法律团队</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <div class="left">优势：</div>
                                          <div class="right"><p>安全性高：及时沟通及交流，内部财务流转效 率更快。</p></div>
                                        </div>
                                        <div class="box">
                                          <div class="left">劣势：</div>
                                          <div class="right"><p>成本较高：需要为企业员工付出工资、缴纳社保及公积金，给予相应的员工福利。</p></div>
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                              </div>
                              <h1>深圳知名会计师事务所名单，均可提供财税业务外包服务</h1>
                              <div class="tab">
                                <el-row>
                                  <el-col :span="6"><div class="tab-header-td">名称</div></el-col>
                                  <el-col :span="6"><div class="tab-header-td">联系地址</div></el-col>
                                  <el-col :span="12"><div class="tab-header-td">网站</div></el-col>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>普华永道深圳办事处</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳市罗湖区深南东路5016号京基100大厦A座34楼</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code1.png" alt="" /></div>
                                        <div class="box"><p>www.pwccn.com/en.html</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>德勤深圳办事处</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳市深南东路5001号 华润大厦13楼</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code2.png" alt="" /></div>
                                        <div class="box"><p>www.deloitte.com/cn/en.html</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>安永深圳办事处</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳市罗湖区深南东路 5001号华润大厦21楼</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code3.png" alt="" /></div>
                                        <div class="box"><p>www.ey.com/en_gl/</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>毕马威深圳办事处</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳南山区科苑南路2666 号中国华润大厦15楼</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code4.png" alt="" /></div>
                                        <div class="box"><p>home.kpmg/cn/zh/home.html</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>立信会计师事务所 有限公司深圳分所</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳市福田区香梅路1061 号中投国际商务中心A栋 16楼</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code5.png" alt="" /></div>
                                        <div class="box"><p>www.bdo.com.cn/en-gb/microsites/english/home</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                              </div>
                              <h1>深圳知名律师事务所名单，均可提供法律业务外包服务</h1>
                              <div class="tab">
                                <el-row>
                                  <el-col :span="6"><div class="tab-header-td">律师事务所名称</div></el-col>
                                  <el-col :span="6"><div class="tab-header-td">联系方式</div></el-col>
                                  <el-col :span="12"><div class="tab-header-td">网站</div></el-col>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>北京市盈科（深圳）律师事务所</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>广东省深圳市福田中心区益田路6003号 荣超商务中心B座3楼</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code6.png" alt="" /></div>
                                        <div class="box"><p>www.yingkelawyer.com</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>广东星辰前海律师 事务所</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳市前海深港现代服务业合作区前湾一路63号前海企业公馆八栋</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code7.png" alt="" /></div>
                                        <div class="box"><p>www.qianhailaw.cn/services</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>华商林李黎 (前海) 联营律师事务所</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳市前海深港现代服务业合作区前海企业公馆3号馆1层B单元</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code8.png" alt="" /></div>
                                        <div class="box"><p>www.hs-lll.cn/en</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>环球律师事务所</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳市南山区铜鼓路39号大冲国际中心5号楼26层B/C单元</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code9.png" alt="" /></div>
                                        <div class="box"><p>www.glo.com.cn/en/</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>北京大成（深圳）律师事务所</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>广东省深圳市福田区深南大道1006号国际创新中心A栋3层、4层</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><img src="../../assets/images/D/sev-code10.png" alt="" /></div>
                                        <div class="box"><p>shenzhen.dachenglaw.com</p></div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane class="cyyy" label="签证选择与移民" name="4">
                  <div class="nav-title">
                    <img class="hidden-sm-and-down" width="100%" src="../../assets/images/D/VISA.png" alt="" />
                    <img class="hidden-md-and-up" width="80%" src="../../assets/images/D/VISA-m.png" alt="" />
                  </div>
                  <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="20" :lg="20">
                      <el-collapse class="mian-left-l">
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/01.png" alt class="enen" />
                            <img src="../../assets/images/D/visa-icon.png" alt class="item-i" />
                            <span class="item-txt-t">签证种类的选择</span>
                            <span class="item-txt-m">Choice of Visa Types</span>
                          </div>
                          <!--                          <div class="tem-img">-->
                          <!--                            <img class="hidden-sm-and-down" width="100%" :src="visa1[0]" alt="" />-->
                          <!--                            <img class="hidden-md-and-up" width="100%" :src="visa1[1]" alt="" />-->
                          <!--                          </div>-->
                          <div v-html="visa.ONE">
                            <div class="loginHall-box">
                              <h1 class="copy-title">外国人办理来华签证的种类111</h1>
                              <div class="tab">
                                <el-row>
                                  <el-col :span="6"><div class="tab-header-td">签证种类</div></el-col>
                                  <el-col :span="18"><div class="tab-header-td">申请人范围</div></el-col>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">C</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          执行乘务、航空、航运任务的国际列车乘务员、国际航空器机组人员、国际航行船舶的船员及船员随行家属和从事国际道路运输的汽车驾驶员
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">D</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">入境永久居留的人员</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">F</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">入境从事交流、访问、考察等活动的人员</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">G</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">经中国过境的人员</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">J1</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">外国常驻（居留超过180日）中国新闻机构的外国常驻记者</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">J2</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">入境进行短期（停留不超过180日）采访报道的外国记者</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">L</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">入境旅游人员</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">M</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">入境进行商业贸易活动的人员</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">Q1</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          因家庭团聚申请赴中国居留的中国公民的家庭成员（配偶、父母、子女、子女的配偶、兄弟姐妹、祖父母、外祖父母、孙子女、外孙子女以及配偶的父母）和具有中国永久居留资格的外国人的家庭成员（配偶、父母、子女、子女的配偶、兄弟姐妹、祖父母、外祖父母、孙子女、外孙子女以及配偶的父母），以及因寄养等原因申请入境居留的人员
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">Q2</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">入境短期（不超过180日）探亲的居住在中国境内的中国公民的亲属和具有中国永久居留资格的外国人的亲属</div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">R</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">国家需要的外国高层次人才和急需紧缺专门人才</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">S1</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          入境长期（超过180日）探亲的因工作、学习等事由在中国境内居留的外国人的配偶、父母、未满18周岁的子女、配偶的父母，以及因其他私人事务需要在中国境内居留的人员
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">S2</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          入境短期（不超过180日）探亲的因工作、学习等事由在中国境内停留居留的外国人的家庭成员（配偶、父母、子女、子女的配偶、兄弟姐妹、祖父母、外祖父母、孙子女、外孙子女以及配偶的父母）以及因其他私人事务需要在中国境内停留的人员
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">X1</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">在中国境内长期（超过180日）学习的人员</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">X2</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">在中国境内短期（不超过180日）学习的人员</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">Z</div></div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td"><div class="box">在中国境内工作的人员</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                              </div>
                            </div>
                            <div class="set-guid">
                              <h1 class="copy-title">
                                外国人办理来华签证的种类
                                <span>（申请来华工作90日以上）</span>
                              </h1>
                              <div class="circuit-content"><img width="100%" src="../../assets/images/D/img_02.png" alt="" /></div>
                              <div class="materialList-box">
                                <h1 class="copy-title">申请办理中华人民共和国签证须知</h1>
                                <div class="subTitle">基本申请材料</div>
                                <div class="content-box">
                                  <p>（1）护照：有效期为6个月以上、有空白签证页的护照原件及护照照片资料页复印件1份；</p>
                                  <p>（2）签证申请表及照片：1份《中华人民共和国签证申请表》及1张粘贴在申请表上的近期、正面、彩色（浅色背景）、免冠、护照照片；</p>
                                  <p>
                                    （3）合法停留或居留证明（适用于不在国籍国申请签证者）：如您不在国籍国申请签证，您需提供在所在国合法停留、居留、工作、学习的有效证明或有效签证的原件和复印件；
                                  </p>
                                  <p>
                                    （4）原中国护照或原中国签证（适用于曾有中国国籍，后加入外国国籍者）：如您系首次申请中国签证，须提供原中国护照原件及护照照片资料页复印件；如您曾获中国签证并持新换发的外国护照申请签证，须提供原外国护照照片资料页及曾获得的中国签证复印件（如果新护照所记载的姓名与原护照不一致，还须提供有关官方出具的更改姓名的证明文件）。
                                  </p>
                                </div>
                                <h1 class="copy-title">外国人来华工作许可受理点地址及对外咨询电话</h1>
                                <div class="subTitle">深圳市外专局</div>
                                <div class="content-box">
                                  <p>地址：深圳市福田区福中三路市民中心B区行政服务大厅东厅</p>
                                  <p>电话：0755-88121678</p>
                                </div>
                              </div>
                              <div>
                                <h1 class="copy-title">疑问解答</h1>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">申请签证是否需要体检报告？</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">不需要。</div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">签证能否查询办证进度？</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">
                                    通常情况下，驻外使领馆可在四到五个工作日内办妥签证，并会在取证单上注明取证日期，请耐心等候。如有特殊情况，可通过电话联系相关使领馆。
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">拿到签证后发现签证次数或停留期不够，可以更改吗？</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">
                                    不可以。申请人如改变计划行程以至于签证次数或停留期不能满足实际需要，可以重新申请签证，但需再次递交申请材料并交费。
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">签证可否办理延期？</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">
                                    入境后，如因故无法按原定计划出境，应在停留期满前到所在地的公安出入境管理部门办理签证延期手续，具体要求请询公安机关。
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">外国公民能否在中国口岸申请签证？</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">
                                    <p>
                                      中国签证申请人原则上应事先向中国驻外使领馆、处、驻香港、澳门公署或外交部授权的其他驻外机构申请办理签证。申请人应根据自己的行程，提前办妥签证。
                                    </p>
                                    <p>
                                      除24小时过境不出机场、京沪等7个城市72小时过境、广东6日便利游及海南15日免签游之外，美国、法国公民来华均须事先在境外办妥签证，而不可在口岸申请签证。
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">外国公民赴香港、澳门特区怎么办理？</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">
                                    <p>香港和澳门特区的入境政策由其自行制定，具体事宜请查询其政府网站。</p>
                                    <p>香港特区入境管理处网站：www.immd.gov.hk；咨询电话：0085228246111。</p>
                                    <p>澳门特区治安警察局网站：http://www.fsm.gov.mo/；咨询电话：0085328725488。</p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">外国公民同时赴中国内地和香港、澳门特区应如何申办签证？</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">外国公民同时前往中国内地、香港特区、澳门特区，须分别申请内地签证和赴港、赴澳签证。</div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">前来中国需要注意什么？</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">
                                    <p>（1）检查签证是否有效，并有足够的入境次数。</p>
                                    <p>（2）外国人携带动植物，货币等中国须遵守相关法律法规。</p>
                                    <p>（3）不得在中国境内从事与其申请签证时申报事由无关的活动。</p>
                                    <p>（4）外国公民不得逾期在华滞留。</p>
                                    <p>（5）未经批准，不得进入限制外国人进入的区域。</p>
                                    <p>（6）下列情况将导致被拒绝登机，被拒绝入境或被处以罚款等处罚，请注意避免：</p>
                                    <p>·未事先办妥签证而前来中国者，（免除签证者除外）；</p>
                                    <p>·持用过期失效签证前来中国者；</p>
                                    <p>·在华逾（停留）期滞留者；</p>
                                    <p>·在华停留期间，护照过期者（无论签证停留期是否有效）。</p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">外国人获得《外国人工作许可通知》后至驻外使、领馆办理签证，是否还需要办理邀请函或邀请确认函？</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">不需要</div>
                                </div>
                              </div>
                              <div>
                                <div class="item-txt-a">
                                  <div class="item-txt-b">Q</div>
                                  <div class="item-txt-c">聘用合同或任职证明（包括跨国公司派遣函）应注意？</div>
                                </div>
                                <div class="item-txt-box clearfloat">
                                  <div class="item-txt-question">A</div>
                                  <div class="item-txt-answer">
                                    聘用合同或任职证明（包括跨国公司派遣函）包含来华工作时间、工作地点、工作内容、薪酬、职位、签字盖章页即可。一般单位申请工作许可业务时，均需提供聘用合同；任职证明的使用情形请参照（外专发[2017]36号）第10页合同的备注要求。
                                  </div>
                                </div>
                              </div>
                              <div class="code-box">
                                <div class="code-txt">
                                  <p>How Do Foreigners</p>
                                  <p>Work Legally in China?</p>
                                </div>
                                <div class="code"><img width="100%" src="../../assets/images/D/code.png" alt="" /></div>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/02.png" alt class="enen" />
                            <img src="../../assets/images/D/id-card.png" alt class="item-i" />
                            <span class="item-txt-t">首次办理外国人永久居留身份证</span>
                            <span class="item-txt-m" style="top: 80px;">
                              <p>Processing Foreigner's Permanent Residence</p>
                              <p>Identity Card for the First Time</p>
                            </span>
                          </div>
                          <div class="tem-img" v-html="visa.TWO">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="visa2[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="visa2[1]" alt="" /> -->
                            <div class="cnts-item i-night2">
                              <div>
                                <h1>申请条件</h1>
                                <p>申请在中国永久居留的外国人应当遵守中国法律，身体健康，无犯罪记录，并符合下列条件之一:</p>
                                <p>1、在中国直接投资、连续三年投资情况稳定且纳税记录良好；</p>
                                <p>
                                  2、在中国任副总经理、副厂长等职务以上或具有副教授、副研究员等副高级职称以上及享受同等待遇，已连续任职满四年、四年内在中国居留累计不少于3年且纳税记录良好；
                                </p>
                                <p>3、对中国有重大、突出贡献以及国家特别需要的外国人；</p>
                                <p>4、前1、2、3项所指人员的配偶及其未满18周岁的未婚子女；</p>
                                <p>
                                  5、中国公民或者在中国获得永久居留资格的外国人的配偶，婚姻关系存续满五年、已在中国连续居留满五年且每年在中国居留时间不少于9个月且有稳定生活保障和住所的；
                                </p>
                                <p>6、未满18周岁的外籍未婚子女投靠中国内地父母的；</p>
                                <p>7、在境外无直系亲属，投靠境内直系亲属，且年满60周岁、已在中国连续居留满五年、每年居留时间不少于9个月且有稳定生活保障和住所。</p>
                              </div>
                              <div>
                                <h1>外国人永久居留身份证首次申请流程</h1>
                                <img src="../../assets/images/D/night-flow.png" alt="" />
                              </div>
                              <div>
                                <h1>收费标准</h1>
                                <p>1、受理费用：人民币1500元；(申请即使不被批准也不予退费)</p>
                                <p>2、《外国人永久居留证》证件费：人民币300元。</p>
                              </div>
                              <div>
                                <h1>注意事项</h1>
                                <p>1、外国有关机构出具的证明文件须经中国驻该国使领馆认证。</p>
                                <p>外文材料须翻译成中文，并在翻译件及翻译公司营业执照复印件上加盖翻译公司公章。</p>
                                <p>2、申请人如需在《外国人永久居留证》上打印中文姓名，应在申请表上“其他需说明的事项”栏目中填写“制证时需要打印中文姓名:XXX”,否则填写“无”。</p>
                                <p>3、有关事项需要公安机关调查核实的，调查时间不计入工作日。根据目前情况，审批时间约需要一年左右。</p>
                              </div>
                              <div>
                                <h1>咨询电话</h1>
                                <p>1、深圳市公安局出入境管理局</p>
                                <i>地址:深圳市罗湖区解放路4016号</i>
                                <i>咨询电话:84465490</i>
                              </div>
                              <div>
                                <p>2、深圳市前海蛇口自贸区管理委员会</p>
                                <i>地址：深圳市南山区东滨路与月亮湾大道交汇处南侧前海深港合作区综合办公楼</i>
                                <i>咨询电话：88988988</i>
                              </div>
                              <div>
                                <p>3、深圳市外国专家局</p>
                                <i>地址：深圳市福田区深南大道8005号深圳人才园</i>
                                <i>咨询电话：88123456</i>
                              </div>
                              <div>
                                <p>4、深圳市侨务办公室</p>
                                <i>地址：深圳市福田区上步中路1023号市府二办6-7楼</i>
                                <i>咨询电话：88136167</i>
                              </div>
                              <div>
                                <p>5、深圳口岸医院</p>
                                <i>地址：深圳市福田区滨河大道皇岗口岸生活区内</i>
                                <i>咨询电话：83774013</i>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/03.png" alt class="enen" />
                            <img src="../../assets/images/D/policy.png" alt="" class="item-i" />
                            <span class="item-txt-t">政策扶持</span>
                            <span class="item-txt-m">Policy support</span>
                          </div>
                          <div class="tem-img" v-html="visa.THREE">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="visa3[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="visa3[1]" alt="" /> -->
                            <div class="cnts-item i-night3">
                              <div>
                                <h1>以下满足条件的外籍人士亦可申请在华永久居留</h1>
                                <ul>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">01</div>
                                        <div class="item-txt-c">在深圳创新创业团队的外籍成员和企业选聘的外籍技术人才</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code1.png" alt="" />
                                            <p>详请请扫描以下二维码</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">02</div>
                                        <div class="item-txt-c">在深圳工作的外籍华人</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code2.png" alt="" />
                                            <p>详请请扫描以下二维码</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">03</div>
                                        <div class="item-txt-c">工资性年收入和个人所得税达到规定标准的外国人</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code3.png" alt="" />
                                            <p>详请请扫描以下二维码</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">04</div>
                                        <div class="item-txt-c">属任职人员的外国人及其配偶、未成年子女 (任职人员及其家属类)</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code4.png" alt="" />
                                            <p>详请请扫描以下二维码</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">05</div>
                                        <div class="item-txt-c">属特殊人员的外国人及其配偶、未成年子女 (特殊人员类)</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code5.png" alt="" />
                                            <p>详请请扫描以下二维码</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">06</div>
                                        <div class="item-txt-c">外国人投靠深圳户籍亲属 (亲属投靠类)</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code6.png" alt="" />
                                            <p>详请请扫描以下二维码</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">07</div>
                                        <div class="item-txt-c">外籍高层次人才工作满三年</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code7.png" alt="" />
                                            <p>详请请扫描以下二维码</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">08</div>
                                        <div class="item-txt-c">未成年外国人因与在深圳的父母团聚 (亲子团聚类)</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code8.png" alt="" />
                                            <p>详请请扫描以下二维码</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">09</div>
                                        <div class="item-txt-c">在中国投资的外国人及其配偶、未成年子女 (投资人员及其家属类)</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code9.png" alt="" />
                                            <p>详请请扫描以下二维码</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div>
                                      <div class="item-txt-a">
                                        <div class="item-txt-b">10</div>
                                        <div class="item-txt-c">与深圳户籍居民结婚的外国人</div>
                                      </div>
                                      <div class="item-txt-box clearfloat">
                                        <div class="item-txt-answer">
                                          <div class="cnts">
                                            <img src="../../assets/images/D/night-code10.png" alt="" />
                                            <p>详请请扫描以下二维码</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane class="cyyy" label="公司设立指引" name="5">
                  <div class="nav-title">
                    <img class="hidden-sm-and-down" width="100%" src="../../assets/images/D/SET.png" alt="" />
                    <img class="hidden-md-and-up" width="50%" src="../../assets/images/D/SET-m.png" alt="" />
                  </div>
                  <el-row type="flex" justify="center">
                    <el-col :sm="24" :md="20" :lg="20">
                      <el-collapse class="mian-left-l">
                        <div class="title-box">
                          <div>
                            <div class="text">公司设立至可以运营，需要经过工商登记（申请公司营业执照）、</div>
                            <div class="text">刻制印章、企业银行开户等环节。</div>
                          </div>
                        </div>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/01.png" alt class="enen" />
                            <img src="../../assets/images/D/pencil.png" alt class="item-i" />
                            <span class="item-txt-t">工商登记（申请公司营业执照）</span>
                            <span class="item-txt-m">Business registrations</span>
                          </div>
                          <!--                          <div class="tem-img">-->
                          <!--                            <img class="hidden-sm-and-down" width="100%" :src="setting1[0]" alt="" />-->
                          <!--                            <img class="hidden-md-and-up" width="100%" :src="setting1[1]" alt="" />-->
                          <!--                          </div>-->
                          <div class="set-guid" v-html="setting.ONE">
                            <h1 class="copy-title">
                              工商登记流程
                              <span>(仅提供中文服务)</span>
                            </h1>
                            <div class="circuit-content">
                              <img width="100%" src="../../assets/images/D/circuit.png" alt="" />
                              <p>备注：审理时间仅供参考。</p>
                            </div>
                            <h1 class="copy-title">
                              外商投资企业设立备案登记流程
                              <span>(仅提供中文服务)</span>
                            </h1>
                            <div class="circuit-content">
                              <img width="100%" src="../../assets/images/D/D5-1-1-img01.jpg" alt="" />
                              <p>备注：审理时间仅供参考。</p>
                            </div>
                            <div class="materialList-box">
                              <h1 class="copy-title">
                                工商登记材料清单
                                <span>到注册大厅现场需携带的材料（仅接受中文文字资料）：</span>
                              </h1>
                              <div class="tab">
                                <el-row>
                                  <el-col :span="6"><div class="tab-header-td">股东架构</div></el-col>
                                  <el-col :span="18"><div class="tab-header-td">材料清单（以下资料仅供参考）</div></el-col>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>自然人</p>
                                          <p>（外商独资）</p>
                                        </div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>1.股东提供个人公证书或到各区注册大厅现场受理人员处面签 ；</p>
                                          <p>2.股东、董事、监事、经理、财务人员证件复印件（港澳：回乡证和身份证；外国人：护照；台湾人：台胞证）</p>
                                          <p>3.股东护照原件；</p>
                                          <p>4.法律文件送达人身份证复印件（必须是中国大陆人）</p>
                                          <p>5.《企业设立登记（一照一码）申请书》（网站下载）</p>
                                          <p>6.公司章程（网站可下载样本）</p>
                                          <p>7.任职书</p>
                                          <p>8.租赁办公室的合同及租赁凭证（实际需根据各区要求提供）</p>
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>公司法人</p>
                                          <p>（外商独资）</p>
                                        </div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>1.外国投资者的主体资格证明；</p>
                                          <p>2.董事、监事、经理、财务人员证件复印件（港澳：回乡证和身份证；外国人：护照；台湾人：台胞证）</p>
                                          <p>3.法律文件送达人身份证复印件（必须是中国大陆人）</p>
                                          <p>4.《企业设立登记（一照一码）申请书》（网站下载）</p>
                                          <p>5.公司章程（网站可下载样本）</p>
                                          <p>6.任职书</p>
                                          <p>7.租赁办公室的合同及租赁凭证（实际需根据各区要求提供）</p>
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box"><p>中外合资企业</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="18">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>1.中方必须为法人主体，不能是自然人股东；提供营业执照副本复印件加盖公司公章；</p>
                                          <p>2.外方可以是自然人或者法人股东；自然人股东提供个人公证书或到各区注册大厅现场受理人员处面签 ；法人股东提供外国投资者的主体资格证明；</p>
                                          <p>3.董事、监事、经理、财务人员证件复印件（港澳：回乡证和身份证；外国人：护照；台湾人：台胞证）</p>
                                          <p>4.法律文件送达人身份证复印件（必须是中国大陆人）</p>
                                          <p>5.《企业设立登记（一照一码）申请书》（网站下载）</p>
                                          <p>6.公司章程（网站可下载样本）</p>
                                          <p>7.任职书</p>
                                          <p>8.租赁办公室的合同及租赁凭证（实际需根据各区要求提供）</p>
                                        </div>
                                      </div>
                                    </el-col>
                                  </div>
                                </el-row>
                              </div>
                            </div>
                            <h1 class="copy-title">商务部业务系统统一平台外商投资综合管理应用申请材料清单：</h1>
                            <div class="list-content">
                              <p>1.外商投资企业商事主体名称证明书（申报流程中第②步下载）；</p>
                              <p>2.外商投资企业设立备案申报承诺书（网站下载样本）；</p>
                              <p>3.外商投资企业设立备案申请委托书（网站下载样本）；</p>
                              <p>4.投资者的主体资格证明或自然人身份证明；</p>
                              <p>5.法定代表人的自然人身份证明；</p>
                              <p>6.外商投资企业最终实际控制人股权架构图（网站下载样本）。</p>
                            </div>
                            <div>
                              <h1 class="copy-title">疑问解答</h1>
                              <div class="item-txt-a">
                                <div class="item-txt-b">Q</div>
                                <div class="item-txt-c">注册资本金如何定合适？</div>
                              </div>
                              <div class="item-txt-box clearfloat">
                                <div class="item-txt-question">A</div>
                                <div class="item-txt-answer">
                                  注册资本金根据企业自身发展的需要制定即可，除特别的行业及自贸区有要求外，并没有最低注册资本金的要求。在公司章程中，可自行规定缴纳注册资本金的期限以及缴纳的次数，可分多次缴纳，每次缴纳的金额亦不做具体要求。
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="item-txt-a">
                                <div class="item-txt-b">Q</div>
                                <div class="item-txt-c">减少注册资本金有什么特别要求？</div>
                              </div>
                              <div class="item-txt-box clearfloat">
                                <div class="item-txt-question">A</div>
                                <div class="item-txt-answer">《公司法》规定，减少注册资本金需在报纸上登公告，45天后方可办理减资登记。</div>
                              </div>
                            </div>
                            <div>
                              <div class="item-txt-a">
                                <div class="item-txt-b">Q</div>
                                <div class="item-txt-c">外资出资比例低于25%，问，能否作为外商投资企业？</div>
                              </div>
                              <div class="item-txt-box clearfloat">
                                <div class="item-txt-question">A</div>
                                <div class="item-txt-answer">
                                  可以。中外合资企业的注册资本中外国投资者的出资比例一般不低于25%。外国投资者的出资比例低于25%的，除法律、行政法规另有规定外，均应按照现行设立外商投资企业的审批登记程序进行审批和登记，登记成功的，颁发在“企业类型”后加注“外资比例低于25%”字样的外商投资企业营业执照。
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="item-txt-a">
                                <div class="item-txt-b">Q</div>
                                <div class="item-txt-c">什么人可以担任法定代表人？</div>
                              </div>
                              <div class="item-txt-box clearfloat">
                                <div class="item-txt-question">A</div>
                                <div class="item-txt-answer">法定代表人只能由董事长、执行董事或经理担任，由哪种人担任由公司章程规定，但不能由这三种人之外的人担任。</div>
                              </div>
                            </div>
                            <div>
                              <div class="item-txt-a">
                                <div class="item-txt-b">Q</div>
                                <div class="item-txt-c">在中国做餐饮类业务，除申请领取营业执照外，是否需要办理其他证件？</div>
                              </div>
                              <div class="item-txt-box clearfloat">
                                <div class="item-txt-question">A</div>
                                <div class="item-txt-answer">需要，还需办理食品经营许可证。</div>
                              </div>
                            </div>
                            <div class="loginHall-box">
                              <div class="title-note">附：深圳市各注册大厅地址及联系方式</div>
                              <div class="tab">
                                <el-row>
                                  <el-col :span="6"><div class="tab-header-td">工商局名称</div></el-col>
                                  <el-col :span="12"><div class="tab-header-td">地址</div></el-col>
                                  <el-col :span="6"><div class="tab-header-td">联系方式</div></el-col>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>前海E站通服务大厅</p>
                                          <p>（前海工商局）</p>
                                        </div>
                                      </div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>深圳市前海深港合作区前湾一路1号</p>
                                          <p>A栋E站通服务大厅A105</p>
                                        </div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">12345</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">福田分局注册大厅</div></div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>福田区新沙路7号</p>
                                          <p>福田工商物价大楼2楼、3楼</p>
                                        </div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">12345</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">南山局注册大厅</div></div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>南山区滨海大道辅路3001号</p>
                                          <p>深圳湾体育中心南山区行政服务大厅</p>
                                          <p>（深圳湾体育中心东南侧独栋楼房）</p>
                                        </div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">12345</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">宝安分局注册大厅</div></div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>深圳市宝安区宝安大道与罗田路交汇处</p>
                                          <p>宝安体育中心综合训练馆一楼</p>
                                          <p>（宝安出入境办证大厅正对面）</p>
                                        </div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">12345</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">龙岗分局注册大厅</div></div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>龙岗区龙城街道龙翔大道8033-1号</p>
                                          <p>（区政府大门外西南侧）</p>
                                        </div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">12345</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">盐田局注册大厅</div></div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box">
                                          <p>盐田区沙头角海景二路1013号</p>
                                          <p>盐田局1楼</p>
                                        </div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">12345</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">光明分局注册大厅</div></div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳市光明新区碧眼路光明新区行政服务大厅</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">12345</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">坪山分局注册大厅</div></div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><p>坪山区金牛西路12号启兴大厦2楼</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">12345</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">大鹏分局注册大厅</div></div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳市大鹏新区大鹏街道中山路10号</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">12345</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                                <el-row>
                                  <div class="tab-content-tr">
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">龙华分局注册大厅</div></div>
                                    </el-col>
                                    <el-col :span="12">
                                      <div class="tab-content-td">
                                        <div class="box"><p>深圳市龙华区梅龙大道98号国鸿大厦A座一楼</p></div>
                                      </div>
                                    </el-col>
                                    <el-col :span="6">
                                      <div class="tab-content-td"><div class="box">12345</div></div>
                                    </el-col>
                                  </div>
                                </el-row>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/02.png" alt class="enen" />
                            <img src="../../assets/images/D/seal.png" alt class="item-i" />
                            <span class="item-txt-t">刻制印章</span>
                            <span class="item-txt-m">Chapter moment</span>
                          </div>
                          <div class="tem-img" v-html="setting.TWO">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="setting2[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="setting2[1]" alt="" /> -->
                            <div class="cnts-item i-eit2">
                              <div>
                                <p>
                                  当深圳市市场和质量监督管理委员会审理办结后，法定代表人将收到【政务短信平台】发送的验证码，凭验证码可通过线上网站或线下刻章店刻章。外资投资企业一般刻制以下印章：
                                </p>
                                <p>(1)公章/行政章（企业银行账户开设必备）</p>
                                <p>(2)财务专用章（企业银行账户开设必备）</p>
                                <p>(3)法定代表人私章（个人名字章）（企业银行账户开设必备）</p>
                                <p>(4)发票专用章（盖在发票上）</p>
                                <p>(5)合同专用章（签署合同）</p>
                              </div>
                              <div class="cnts2">
                                <p>备注：</p>
                                <p>线下刻章店刻章，必须携带资料有：</p>
                                <p>营业执照正本/副本原件；</p>
                                <p>法定代表人护照原件；</p>
                                <p>经办人身份证原件。</p>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                        <el-collapse-item>
                          <div slot="title" class="item-txt">
                            <img src="../../assets/images/D/03.png" alt class="enen" />
                            <img src="../../assets/images/D/wallet.png" alt="" class="item-i" />
                            <span class="item-txt-t">公司银行账户开设</span>
                            <span class="item-txt-m">Company bank account opening</span>
                          </div>
                          <div class="tem-img" v-html="setting.THREE">
                            <!-- <img class="hidden-sm-and-down" width="100%" :src="setting3[0]" alt="" />
                            <img class="hidden-md-and-up" width="100%" :src="setting3[1]" alt="" /> -->
                            <div class="cnts-item i-eit3">
                              <p style="text-indent: 2em;padding-bottom: .4rem;">
                                当您已经申请了营业执照和章，可以开始企业的营运，以公司名义签署合同，需要向供应商收款，这时候，就需要开设企业的银行账户，而不能直接把股东的银行账号提供给供应商付款，否则有偷税漏税的嫌疑，成为税务局重点的关注对象。
                              </p>
                              <div>
                                <h1>企业银行账户种类</h1>
                                <p style="padding-bottom: .2rem;">
                                  ①
                                  基本户（必备，且只能有一个）。基本户是企业的第一个银行账户，必须有基本户，否则不能开其他银行帐户，基本户可以存取现金、转帐收付款、代扣税款、代发工资等。
                                </p>
                                <p>② 外汇资本金户（外资企业必备）：</p>
                                <p>
                                  接收中外方股东以外汇方式投入的资本金。股东在国外银行向国内的外汇资本金户付款时，必须注明用途为“投资款”或者“资本金”，否则在注册会计师出具验资报告或者审计报告时，很难确认此款项就是注册资本金。
                                </p>
                                <p>同时，您的开户行将根据外汇管理局的指导要求对注册资本金如何使用作了明确的规定，具体以各银行的要求为准。</p>
                                <p style="padding-bottom: .2rem;">例如：一家外资贸易公司，需要向供货商进行付款，必须拿已签署的合同、发票等资料到银行柜台申请后方能对外支付。</p>
                                <p>③一般户（非必要，可以有多个）</p>
                                <p style="padding-bottom: .2rem;">企业可以在本地开立一般户，一般户可以存现金，但不能支取现金，只能办理转帐交易，同时也可以代扣税款等。</p>
                              </div>
                              <div>
                                <h1>怎么寻找银行的相关信息？</h1>
                                <p style="text-indent: 2em;">
                                  在中国，您可以通过拨打各银行的客服电话，例如中国银行：95566。您可以告知银行您想要在深圳的哪个区开设公司银行账户，银行客服人员亦会告知您银行的电话及地址，接下来，您可以拨打银行电话或者根据客服提供的地址，到银行柜台咨询想要了解的信息。
                                </p>
                              </div>
                              <div class="cnts2">
                                <div class="tab">
                                  <i>附：重要银行客服电话</i>
                                  <i>按照以上方式拨打即可转接英文服务：</i>
                                  <el-row>
                                    <el-col :span="8"><div class="tab-header-td">银行</div></el-col>
                                    <el-col :span="16"><div class="tab-header-td">客服电话</div></el-col>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="8">
                                        <div class="tab-content-td"><div class="box">中国银行</div></div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-content-td"><div class="box">95566转8</div></div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="8">
                                        <div class="tab-content-td"><div class="box">建设银行</div></div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-content-td"><div class="box">95533转4</div></div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="8">
                                        <div class="tab-content-td"><div class="box">工商银行</div></div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-content-td"><div class="box">95588转0</div></div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="8">
                                        <div class="tab-content-td"><div class="box">农业银行</div></div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-content-td"><div class="box">95599转9</div></div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="8">
                                        <div class="tab-content-td"><div class="box">招商银行</div></div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-content-td"><div class="box">95555转8</div></div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="8">
                                        <div class="tab-content-td"><div class="box">浦发银行</div></div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-content-td"><div class="box">95528转2</div></div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="8">
                                        <div class="tab-content-td"><div class="box">中信银行</div></div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-content-td"><div class="box">95558转4</div></div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="8">
                                        <div class="tab-content-td"><div class="box">民生银行</div></div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-content-td"><div class="box">95568转9</div></div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="8">
                                        <div class="tab-content-td"><div class="box">光大银行</div></div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-content-td"><div class="box">95595转*转7</div></div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                  <el-row>
                                    <div class="tab-content-tr">
                                      <el-col :span="8">
                                        <div class="tab-content-td"><div class="box">广发银行</div></div>
                                      </el-col>
                                      <el-col :span="16">
                                        <div class="tab-content-td"><div class="box">95508转7#</div></div>
                                      </el-col>
                                    </div>
                                  </el-row>
                                </div>
                              </div>
                              <div>
                                <h1>开户地点的选择：</h1>
                                <p>
                                  1.选择就近的银行。您可以观察办公室楼下或者附近是不是有心仪的银行，可以考虑选择就近的银行，公司未来运营后，需要常常跑银行，可以节省跑银行的时间；
                                </p>
                                <p>
                                  2.选择熟悉的银行。如在国外已经使用过中国银行的服务，那么同样可以在深圳选择一家中国银行来开设公司银行账户，在熟悉银行运营模式及管理模式的情况下，方便了解更多讯息。
                                </p>
                              </div>
                              <div>
                                <h1>预约开户的方式有哪些？</h1>
                                <p>1.通过电话与银行工作人员预约；</p>
                                <p>2.到银行现场预约；</p>
                                <p>3.通过银行网站或者关注银行微信公众号预约。公众号仅提供中文服务，且必须有一个可以接收大陆手机验证码的大陆手机号码。</p>
                              </div>
                              <div>
                                <h1>对于银行，您需要了解的费用有：</h1>
                                <p>1.开户手续费；</p>
                                <p>2.银行账户管理费（一般按每月收取）；</p>
                                <p>3.办理网银盾的费用；</p>
                                <p>4.网银管理费（按每月或每季度收取）；</p>
                                <p>5.转账手续费。</p>
                              </div>
                              <div>
                                <h1>
                                  基本户办理流程：
                                  <span>（仅供参考，具体以各银行要求为准）</span>
                                </h1>
                                <img src="../../assets/images/D/eit3-flow.png" alt="" />
                              </div>
                              <div>
                                <h1>
                                  基本户开设材料清单：
                                  <span>（仅供参考，具体以各银行要求为准）</span>
                                </h1>
                                <p>1.公司营业执照正本；</p>
                                <p>2.租赁办公室的租赁合同、租赁凭证；</p>
                                <p>3.前海住所托管协议（前海自贸区企业必备）</p>
                                <p>4.法定代表人需到银行柜台办理，需配合银行工作人员拍照；</p>
                                <p>5.法定代表人护照原件；</p>
                                <p>6.公司公章、财务章、法定代表人私章；</p>
                                <p>7.网银管理员护照原件；</p>
                                <p>8.自然人股东护照原件。</p>
                                <p style="color: #0fb7d1;padding-top: .2rem;">现场办理时，银行柜台工作人员可能问法定代表人以下问题：</p>
                                <p>1、公司注册地址在哪里？是否就是实际办公地址？</p>
                                <p>2、公司主要做什么业务？</p>
                                <p>3、公司目前有多少个员工？</p>
                                <p>4、公司股东有哪些？占比分别多少？</p>
                                <p>5、开银行账户做什么？</p>
                                <p>6、预计年度销售收入是多少万？</p>
                                <p>以上问题无具体标准答案，按照公司实际情况回答即可。</p>
                              </div>
                              <div>
                                <h1>
                                  外汇资本金户办理流程：
                                  <span>（仅供参考，具体以各银行要求为准）</span>
                                </h1>
                                <img src="../../assets/images/D/eit3-flow2.png" alt="" />
                              </div>
                              <div>
                                <h1>
                                  办理FDI业务登记凭证材料清单：
                                  <span>（仅供参考，具体以各银行要求为准）</span>
                                </h1>
                                <p>1.营业执照正本原件；</p>
                                <p>2.租赁办公室的租赁合同、租赁凭证；</p>
                                <p>3.法定代表人护照原件及复印件（是否需亲自到银行现场，按各银行要求）；</p>
                                <p>4.开户许可证原件及复印件；</p>
                                <p>5.公司公章、财务章、法人私章；</p>
                                <p>6.外商投资企业设立备案回执原件；</p>
                                <p>7.外商投资企业设立备案申报表；</p>
                                <p>8.公司章程；</p>
                                <p>9.经办人身份证原件；</p>
                                <p>10.内资公司股东营业执照副本复印件加盖公章（中外合资企业需有）；</p>
                                <p>11.国外自然人股东护照复印件；</p>
                                <p>12.国外公司股东注册证书复印件。</p>
                              </div>
                              <div>
                                <h1>
                                  外汇资本金户开设材料清单：
                                  <span>（仅供参考，具体以各银行要求为准）</span>
                                </h1>
                                <p>1.营业执照正本原件；</p>
                                <p>2.租赁办公室的租赁合同、租赁凭证；</p>
                                <p>3.前海住所托管协议（前海自贸区企业必备）</p>
                                <p>4.法定代表人需到银行现场办理，需配合银行工作人员拍照；</p>
                                <p>5.法定代表人护照原件及复印件；</p>
                                <p>6.公司公章、财务章、法人私章；</p>
                                <p>7.支付联系人护照原件及复印件；</p>
                                <p>8.外商投资企业设立备案回执原件；</p>
                                <p>9.外商投资企业设立备案申报表；</p>
                                <p>10.公司章程；</p>
                                <p>11.经办人身份证原件；</p>
                                <p>12.业务登记凭证原件。</p>
                              </div>
                              <div>
                                <h1>外汇资本金账户结汇使用说明：</h1>
                                <p>资本金账户的收入范围主要包括：外国投资者境外汇入外汇资本金或认缴出资及其产生的利息收入。</p>
                                <p>
                                  资本金账户的支出范围主要包括：经营范围内结汇，因外国投资者减资、撤资汇出，对外支付货款及服务费，发放工资，提取备用金（每个企业每月提取备用金累计金额不得超过等值10万美元。）
                                </p>
                                <p>资本金账户如何对外支付货款或者服务费？</p>
                                <p>若想从资本金账户中直接向供应商支付货款或者服务费，需到所在银行柜台提交相关资料后由银行方汇出。</p>
                                <p>1.服务贸易。需提交服务合同、发票等资料；</p>
                                <p>2.货物贸易。需提交货物合同、发票、海关缴款书等资料。</p>
                              </div>
                              <div>
                                <h1>疑问解答</h1>
                                <ul>
                                  <li>
                                    <div class="item-txt-a">
                                      <div><div class="item-txt-b">Q</div></div>
                                      <div class="item-txt-c">在准备开设外资企业银行基本户前，有什么特别注意要点？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div><div class="item-txt-question">A</div></div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">法定代表人和网银管理员都需要申请一个大陆手机号码，用于跟银行保持联系、接收验证码等。</div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div><div class="item-txt-b">Q</div></div>
                                      <div class="item-txt-c">在刻章完成后，需要在什么时间内办理完成开设外资企业银行基本户（公司第一个账户）？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div><div class="item-txt-question">A</div></div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          针对企业银行账户开设的时间没有具体的要求，企业可以根据自身实际运营情况安排时间办理。如果近期没有业务，可暂不开设银行基本户。办理基本户及外汇资本金户大约需要1-2个月的时间，具体以各银行实际情况为准。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div><div class="item-txt-b">Q</div></div>
                                      <div class="item-txt-c">外汇资本金账户的币种可以有哪些？不同币种的账户有什么区别？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div><div class="item-txt-question">A</div></div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          外汇资本金账户的币种可为境外人民币或者其他外币（主要为美金）。不论是哪种币种的外汇资本金账户使用上并没有太大区别，在申请过程的时间上，境外人民币资本金账户会更长，银行将增加人民币合规性审查，对企业在国外的人民币取得来源及合法性等进行审查。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div><div class="item-txt-b">Q</div></div>
                                      <div class="item-txt-c">办理银行账户开设申请时，必须法定代表人到银行柜台办理，那领取印鉴卡及开户许可证还必须法定代表人到银行柜台办理吗？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div><div class="item-txt-question">A</div></div>
                                      <div class="item-txt-answer"><div class="cnts">除申请当天必须法定代表人到场外，领取环节可授权公司员工到银行柜台办理。</div></div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div><div class="item-txt-b">Q</div></div>
                                      <div class="item-txt-c">企业网银U盾有多少个？如何使用？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div><div class="item-txt-question">A</div></div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          2-3个。2个网银U盾的功能分别是（制单、复核），一般情况下，以法定代表人持有复核功能的网银U盾，企业财务人员持有制单功能的的网银U盾。用网银U盾付款时，是无法一个人操作的，至少需要两人操作（制单、复核），保障企业的资金安全。
                                          <br />
                                          领取企业网银U盾后，可以在银行请银行工作人员协助激活网银U盾后即可使用。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="item-txt-a">
                                      <div><div class="item-txt-b">Q</div></div>
                                      <div class="item-txt-c">企业银行账户开设后，是不是就不用管理？</div>
                                    </div>
                                    <div class="item-txt-box clearfloat">
                                      <div><div class="item-txt-question">A</div></div>
                                      <div class="item-txt-answer">
                                        <div class="cnts">
                                          各银行跟企业之间，需每月/每季度对账一次，企业可以通过登录网银U盾进行对账。如果不能够及时对账，将很可能被银行列入久悬户，银行账户被冻结。一旦被列为久悬户，需要先解冻再重新开户，办理流程繁琐并且耗时长。
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
              <div class="downn"><i class="el-icon-caret-bottom"></i></div>
            </div>
            <gotop></gotop>
          </el-col>
        </el-row>
      </div>
    </el-main>
    <el-footer>
      <vfooter class="hidden-sm-and-down"></vfooter>
      <ivfooter class="hidden-md-and-up"></ivfooter>
    </el-footer>
  </el-container>
</template>

<script>
import { szLifed1, szLifed11, szLifed21, szLifed22, szLifed3, szLifed4, szLifed5 } from '../../api'
import Gotop from '../../components/gotop'
export default {
  data () {
    return {
      activeName: 'first',
      tabPosition: 'right',
      acName: '1',
      btnFlag: false,
      subShow: false,
      subsidies: {},
      subsidies1: '',
      subsidies2: '',
      essential: {},
      essential12: {},
      essential13: {},
      essential14: {},
      essential2: {},
      essential21: {},
      essential22: {},
      essential23: {},
      taxLaw: {},
      taxLaw2: {},
      taxLaw3: {},
      taxLaw4: {},
      visa: {},
      visa1: {},
      visa2: {},
      visa3: {},
      setting: {},
      setting1: {},
      setting2: {},
      setting3: {},
      navTitle: [
        {
          txt: '个人政策'
        },
        {
          txt: '企业政策'
        }
      ],
      menuShow: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
    szLifed1().then(res => {
      this.subsidies = res.data[0]
      // console.log(res.data)
    })
    szLifed11().then(res => {
      this.subsidies1 = res.data[0].CONTENT
      this.subsidies2 = res.data[0].CONTENT2
      // this.subsidies1 = this.cutSrc(this.subsidies1)
    })
    szLifed21().then(res => {
      this.essential = res.data[0]
      this.essential12 = this.cutSrc(res.data[0].TWO)
      this.essential13 = this.cutSrc(res.data[0].THREE)
      this.essential14 = this.cutSrc(res.data[0].FOUR)
    })
    szLifed22().then(res => {
      this.essential2 = res.data[0]
      this.essential21 = this.cutSrc(res.data[0].TWO)
      this.essential22 = this.cutSrc(res.data[0].THREE)
      this.essential23 = this.cutSrc(res.data[0].FOUR)
    })
    szLifed3().then(res => {
      this.taxLaw = res.data[0]
      this.taxLaw2 = this.cutSrc(res.data[0].TWO)
      this.taxLaw3 = this.cutSrc(res.data[0].THREE)
      this.taxLaw4 = this.cutSrc(res.data[0].FOUR)
    })
    szLifed4().then(res => {
      this.visa = res.data[0]
      this.visa1 = this.cutSrc(res.data[0].ONE)
      this.visa2 = this.cutSrc(res.data[0].TWO)
      this.visa3 = this.cutSrc(res.data[0].THREE)
      // console.log(this.visa)
    })
    szLifed5().then(res => {
      this.setting = res.data[0]
      this.setting1 = this.cutSrc(res.data[0].ONE)
      this.setting2 = this.cutSrc(res.data[0].TWO)
      this.setting3 = this.cutSrc(res.data[0].THREE)
      // console.log(this.setting)
    })
    this.$nextTick(() => {
      $('#pane-1 #tab-first').append(
        `
			<p class="tit">个人政策</p>
			<p class="tit-name">Subsidy policies for individuals</p>
			`
      )
      $('#pane-1 #tab-second').append(
        `
			<p class="tit">企业政策</p>
			<p class="tit-name">Policies for enterprises</p>
			`
      )
      $('#pane-2 #tab-first').append(
        `
			<p class="tit">公司性质选择</p>
			<p class="tit-name">Choosing the right company type</p>
			`
      )
      $('#pane-2 #tab-second').append(
        `
			<p class="tit">知识产权</p>
			<p class="tit-name">Intellectual property</p>
			`
      )
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  components: {
    Gotop
  },
  methods: {
    cutSrc (obj) {
      var a = obj.split('</p>')
      var b = new Array()
      for (var i = 0; i < a.length; i++) {
        if (a[i].indexOf('src') !== -1) {
          b.push(a[i].match(/src="(\S*)"/)[1])
        }
      }
      return b
    },
    menuOpen () {
      this.menuShow = !this.menuShow
    },
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    subSelect (e) {
      if (e === 0) {
        this.activeName = 'first'
      } else if (e === 1) {
        this.activeName = 'second'
      }
    },
    handleOpen (e) {
      // console.log(e)
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop () {
      let that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    mainSelect (e) {
      // console.log(e)
      this.acName = e
      this.menuShow = false
      this.backTop()
      if (e == 1) {
        this.navTitle = [
          {
            txt: '个人政策'
          },
          {
            txt: '企业政策'
          }
        ]
      } else if (e == 2) {
        this.navTitle = [
          {
            txt: '公司性质选择'
          },
          {
            txt: '知识产权'
          }
        ]
      }
      // console.log(document.getElementsByClassName('el-menu--inline'))
      // document.getElementsByClassName('el-menu--inline')[0].setAttribute('style', 'display:none')
    },
    getElementTopLeft (obj) {
      var top = 0
      var left = 0
      while (obj) {
        top += obj.offsetTop
        left += obj.offsetLeft
        obj = obj.offsetParent
      }
      return {
        top: top,
        left: left
      }
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop () {
      let that = this
      let num = 1
      if (this.acName <= 2) {
        num = this.acName
      } else {
        num = 3
      }
      let menuObj = document.getElementsByClassName('el-tabs__header')[num]
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 100) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
      if (num <= 2) {
        if (num === 1) {
          // eslint-disable-next-line standard/object-curly-even-spacing
          this.navTitle = []
          this.navTitle = this.navTitle.concat(
            {
              txt: '公司性质'
            },
            {
              txt: '知识产权'
            }
          )
        }
        if (this.getElementTopLeft(menuObj).top + menuObj.clientHeight > window.pageYOffset && window.pageYOffset + window.innerHeight > this.getElementTopLeft(menuObj).top) {
          this.subShow = false
          // console.log(num)
        } else {
          this.subShow = true
        }
      } else {
        this.subShow = false
      }
    }
  }
}
</script>

<style lang="less">
.cityD{
  .el-collapse-item__content img {
    width: 100%;
  }

  .tem-img {
    padding: 0.7rem 0.4rem 0.2rem 0.4rem;
  }

  .nav-title {
    width: 80%;
    position: relative;
    text-align: left;
    margin-bottom: 0.8rem;
    /* margin-left: .58rem; */
  }

  .el-main {
    margin-top: 0;
    overflow: initial;
  }

  .mida {
    position: relative;
  }

  .mida .ec-top-foot {
    position: absolute;
    top: -1.23rem;
    left: 7%;
    background: rgba(0, 182, 205, 1);
    min-width: 6.5rem;
    height: 0.78rem;
    align-items: center;
  }

  .mida .ec-top-foot .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 10px;
  }

  .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
  }

  .el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
  }

  .sin {
    position: fixed;
    right: 1rem;
    top: 50%;
    width: 5px;
  }

  .sin a {
    display: inline-block;
    width: 3px;
    height: 60px;
    background: #d8d8d8;
  }

  .sin a:hover {
    width: 5px;
    background: #b1b0b0;
  }

  .el-popover {
    padding: 0;
  }

  .el-popover .el-popover__title {
    height: 20px;
    font-size: 0.3rem;
    text-align: center;
    align-items: center;
    line-height: 34px;
    color: #6a6969;
  }

  .nav-title-t {
    position: absolute;
    left: 82px;
    bottom: 16px;
    font-size: 26px;
    color: rgba(70, 70, 70, 1);
  }

  .mii .title-box {
    background: #f8f8f8;
    padding: 40px 0;
  }

  .mii .title-box .text {
    font-size: 0.3rem;
    font-weight: normal;
    color: #0fb7d1;
    text-align: left;
    padding: 0 100px;
  }

  .mii .title-box .text:nth-child(1) {
    margin-bottom: 5px;
  }

  .enkt img {
    position: absolute;
    top: 0;
    left: -30px;
    width: 890px;
    height: 100%;
    margin: 0;
  }

  .enkt .enkt-t {
    position: absolute;
    left: 53px;
    bottom: 22px;
    font-size: 24px;
    font-weight: 500;
    color: #464646;
  }

  .mian-right {
    position: relative;
    margin: auto;
    margin-top: 30px;
    padding-bottom: 2rem;
  }

  .mian-right h2 {
    position: absolute;
    right: 0;
    top: 0;
    padding-bottom: 30px;
  }

  .mian-right .nav {
    position: absolute;
    right: 58px;
    top: 50px;
  }

  .mian-right .nav div:nth-child(1) {
    font-size: 0.5rem;
    color: #0fb7d1;
    text-align: left;
    font-weight: bold;
    line-height: 40px;
  }

  .mian-right .nav div:nth-child(2) {
    font-size: 0.5rem;
    color: #464646;
    text-align: left;
    line-height: 25px;
  }

  .enen {
    position: absolute;
    top: 9px;
    left: 6px;
    height: 100px;
  }

  .item-txt-t {
    position: absolute;
    left: 103px;
    top: 45px;
    font-size: 0.3rem;
    font-weight: 400;
    color: #6a6969;
  }

  .item-txt-m {
    position: absolute;
    left: 103px;
    top: 64px;
    font-size: 0.1rem;
    color: #6a6969;
  }

  .item-txt-m p {
    line-height: 13px;
    text-align: left;
    font-size: 0.1rem;
    line-height: 0.22rem;
  }

  .item-i {
    position: absolute;
    left: 105px;
    top: 22px;
    height: 30px;
  }

  .item-txt-a {
    position: relative;
    background: #f8f8f8;
    margin: 0.2rem 0rem 0px 0.2rem;
  }

  .item-txt-b {
    float: left;
    width: 0.9rem;
    line-height: 0.9rem;
    background: #16b9d2;
    color: #fff;
    font-size: 0.4rem;
    text-align: center;
    position: absolute;
    height: 100%;
  }

  .item-txt-c {
    text-align: left;
    line-height: 0.9rem;
    padding-left: 1.2rem;
    padding-right: 0.2rem;
    font-size: 0.3rem;
  }

  .item-txt-d {
    margin: 0.14rem 0.4rem 0 0.4rem;
    text-align: left;
    padding-left: 1.2rem;
    font-size: 0.3rem;
  }

  .item-txt-box {
    position: relative;
    margin: 0.2rem 0.4rem 0 0.2rem;
  }

  .item-txt-question {
    height: 0.9rem;
    width: 0.9rem;
    line-height: 0.9rem;
    background: #aad044;
    color: #fff;
    float: left;
    font-size: 0.4rem;
    text-align: center;
  }

  .top-img {
    position: relative;
  }

  .top-img-top {
    position: absolute;
    top: 45%;
    left: 5%;
    color: #ffffff;
    text-align: left;
  }

  .top-img-top p {
    font-size: 0.6rem;
    font-weight: 600;
  }

  .top-img-top span {
    font-size: 0.45rem;
    font-weight: 300;
  }

  .top-img .top-img-top:after {
    content: '';
    position: absolute;
    left: 0;
    top: -16px;
    height: 1px;
    width: 40%;
    background: #fff;
  }

  .item-txt-answer {
    font-size: 0.27rem;
    padding-left: 1.2rem;
    padding-top: 0.1rem;
    line-height: 0.6rem;
    color: #6a6969;
    text-align: left;
    word-break: break-word;
  }

  /*.item-header{*/
  /*  padding-top: 10px;*/
  /*  width: 800px;*/
  /*}*/
  /*.item-header .item-header-s span{*/
  /*  display: inline-block;*/
  /*  width: 250px;*/
  /*  background: #f8f8f8;*/
  /*  padding: 10px 0;*/
  /*  text-align: center;*/
  /*  color: #b1b0b0;*/
  /*}*/
  /*.item-header .item-header-s span:nth-child(2){*/
  /*  margin: 0 25px;*/
  /*}*/
  /*.item-header .item-header-text{*/
  /*  padding: 10px  23px 0 23px;*/
  /*  text-align: left;*/
  /*}*/
  /*.item-header .item-header-text p*/
  /*{*/
  /*  text-indent:2em;*/
  /*  color: #b1b0b0;*/
  /*  padding-bottom: 10px;*/
  /*  margin-bottom: 0;*/
  /*}*/
  /*.copyright-info{*/
  /*  margin: 30px 0;*/
  /*}*/
  /*.copyright-info .copyright-info-t{*/
  /*  display: inline-block;*/
  /*  margin-top: 25px;*/
  /*  width: 550px;*/
  /*  color: #6a6969;*/
  /*}*/
  /*.copyright-info .copyright-info-t span{*/
  /*  display: inline-block;*/
  /*  width: 100%;*/
  /*  text-align: left;*/
  /*}*/
  /*.copyright-info .copyright-info-t p{*/
  /*  padding-top: 17px;*/
  /*  text-align: left;*/
  /*}*/
  /*.copyinfo-info-code{*/
  /*  float: right;*/
  /*  margin-right: 75px;*/
  /*}*/
  /*.copyinfo-info-code p{*/
  /*  margin: 0 auto;*/
  /*  padding: 0;*/
  /*}*/
  /*.copy-title{*/
  /*  color: #0fb7d1;*/
  /*  font-weight: normal;*/
  /*  text-align: left;*/
  /*}*/
  /*.copy-introduce p{*/
  /*  height: 500px;*/
  /*}*/
  /*.copy-introduce .flow-img{*/
  /*  margin: 50px 0;*/
  /*}*/
  /*.copy-introduce .copy-statute-link img{*/
  /*  padding: 0 10px;*/
  /*}*/
  .item-header .item-header-s .span {
    display: inline-block;
    width: 100%;
    background: #f8f8f8;
    padding: 10px 0;
    text-align: center;
    color: #b1b0b0;
    font-size: 0.25rem;
  }

  .item-header .item-header-s span:nth-child(2) {
    margin: 0 25px;
  }

  .item-header .item-header-text {
    padding: 20px 15px 0 15px;
    text-align: left;
    font-size: 0.25rem;
    line-height: 24px;
  }

  .item-header .item-header-text p {
    text-indent: 2em;
    color: #b1b0b0;
    padding-bottom: 10px;
  }

  .copyright-info {
    margin: 30px 0;
  }

  .copyright-info .copyright-info-t {
    display: inline-block;
    float: left;
    color: #6a6969;
    font-size: 0.3rem;
    width: 70%;
    margin-left: 0.2rem;
  }

  .copyright-info .copyright-info-t span {
    display: inline-block;
    width: 100%;
    text-align: left;
  }

  .copyright-info .copyright-info-t p {
    padding-top: 17px;
    text-align: left;
  }

  .copyinfo-info-code {
    float: right;
    margin-right: 0.5rem;
    width: 20%;
  }

  .copyinfo-info-code p {
    margin: 0 auto;
    padding: 0;
  }

  .copy-title {
    color: #0fb7d1;
    font-weight: normal;
    text-align: left;
    font-size: 0.4rem;
    margin: 10px 0;
    /*padding-left: 0.4rem;*/
  }

  .copy-title:not(:first-child) {
    margin-top: 1.2rem;
  }

  /*.copy-introduce p {*/
  /*  height: 500px;*/
  /*}*/

  .copy-introduce .flow-img {
    margin: 50px 0;
    width: 100%;
  }

  .copy-introduce .copy-statute-link {
    margin: 0.5rem 0;
    display: inline-block;
    width: 100%;
  }

  .go-top {
    position: fixed;
    bottom: 20px;
    right: 50px;
    vertical-align: middle;
  }

  .downn {
    position: absolute;
    right: 130px;
    top: 332px;
    color: #0fb7d1;
  }

  .el-menu-vers {
    width: 10px;
    min-height: 400px;
  }

  .el-tabs__nav-wrap .el-tabs__nav {
    width: 100%;
    padding-bottom: 0.4rem;
  }

  .el-tabs__item {
    color: #464646;
    padding: 0;
  }

  .mian-left-l .el-tabs__header {
    height: 1.6rem;
  }

  .mian-left-l .el-tabs__nav-wrap {
    background: #f8f8f8;
  }

  .mian-left-l .el-tabs__item {
    height: 85px;
    width: 50%;
    line-height: 85px;
    font-size: 0.5rem;
    color: #b1b0b0;
  }

  .mian-left-l .el-tabs__active-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 50% !important;
    background: #0fb7d1;
  }

  .mian-left-l .el-tabs__item:hover {
    color: #6a6969;
  }

  .is-right > .el-tabs__item:hover {
    color: #0fb7d1;
  }

  .mian-left-l .el-tabs__item .is-active {
    background: #0fb7d1;
  }

  .mian-left-l .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-collapse-item__header {
    position: relative;
    border: none;
    height: 110px;
    margin-top: 27px;
    background: #f8f8f8;
    padding: 10px;
  }

  .el-collapse-item__header:hover .item-txt-t,
  .el-collapse-item__header:hover .item-txt-m {
    color: #000;
  }

  .el-collapse {
    border: none;
  }

  .el-collapse-item__arrow {
    font-size: 0.6rem;
    color: #6e6d6d;
    position: absolute;
    top: 55%;
    right: 10%;
  }

  .is-right > .el-tabs__item {
    margin-left: 10px;
    border-bottom: 1px solid #87dbe8;
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
  }

  .is-right > .is-active {
    background: #0fb7d1;
    color: #fff;
  }

  .is-right > .is-active:hover {
    color: #fff;
  }

  .is-right > .is-active:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 8.7px 5px 0;
    border-color: transparent #0fb7d1 transparent transparent;
    content: '';
    position: absolute;
    z-index: 50;
    top: 43%;
    left: -5px;
  }

  .is-right > .el-tabs__nav-wrap {
    margin-top: 40px;
  }

  .is-right > .el-tabs__active-bar {
    border: none;
    background: transparent;
  }

  .is-right > .el-tabs__nav-wrap::after {
    height: 0;
    background: transparent;
  }

  .is-right > .el-tabs__content {
    position: relative;
  }

  /*税务*/
  .tab {
    margin-top: 20px;
  }

  .tab .el-row {
    margin-bottom: 10px;
  }

  .tab .tab-header-td {
    position: relative;
    font-size: 0.3rem;
    height: 1.1rem;
    line-height: 1.1rem;
    color: #fff;
    background: #0fb7d1;
  }

  .tab .el-row .el-col:not(:last-child) .tab-header-td:after {
    content: '';
    position: absolute;
    top: 15%;
    right: 0;
    height: 70%;
    border-right: 1px solid #f8f8f8;
  }

  .tab .tab-content-tr {
    display: flex;
    background: #cdf2f8;
  }

  .tab .tab-content-td {
    position: relative;
    height: 100%;
    color: #6a6969;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem 0.5rem;
  }

  .tab .el-row .el-col:last-child .tab-content-td {
    text-align: left;
  }

  .tab .el-row .el-col:not(:last-child) .tab-content-td:after {
    content: '';
    position: absolute;
    top: 8%;
    right: 0;
    height: 85%;
    border-right: 1px solid #b4d0d4;
  }

  .el-tabs--right .el-tabs__header.is-right {
    margin-top: 90px !important;
    position: relative;
    right: 62px;
  }

  /*补贴政策*/
  .policy-centent-box {
    position: relative;
    display: flex;
    padding: 20px 0;
    margin: 0.5rem 0;
    /*z-index: -2;*/
  }

  .policy-centent-box:nth-child(2n + 1) {
    background: #f8f8f8;
  }

  .policy-centent-box .bg-number {
    position: absolute;
    top: 0;
    left: 5%;
    font-size: 2rem;
    color: rgba(241, 241, 241, 1);
    z-index: -1;
  }

  .policy-centent-box .policy-centent .main-title {
    font-size: 0.4rem;
    text-align: left;
    color: rgb(106, 105, 105);
    margin-top: 1.3rem;
    margin-left: 6%;
  }

  .policy-centent-box .policy-centent .sub-title {
    text-align: left;
    color: #0fb7d1;
    font-size: 0.4rem;
    margin-left: 6%;
    margin-top: 0.5rem;
  }

  .policy-centent-box .policy-centent .content-txt p {
    text-align: left;
    color: #464646;
    font-size: 0.3rem;
    margin: 0 6%;
    line-height: 0.6rem;
  }

  .policy-centent-box .policy-centent .content-txt p:first-child {
    margin-top: 0.1rem;
  }

  .policy-centent-box .code-box {
    position: relative;
    height: 100%;
  }

  .policy-centent-box .code-box .code {
    position: absolute;
    bottom: 52px;
    left: -26px;
  }

  .policy-centent-box .code-box .code-txt {
    position: absolute;
    bottom: 19px;
    left: 15px;
    font-size: 0.25rem;
    color: rgb(70, 70, 70);
  }

  /*设立指引*/
  .set-guid {
    padding-top: 1rem;
  }

  .circuit-content {
    width: 70%;
    margin: 0.5rem auto;
  }

  .set-guid .copy-title span {
    color: #6a6969;
    font-size: 0.25rem;
  }

  .circuit-content p {
    text-align: left;
    color: #6a6969;
    font-size: 0.3rem;
    margin-top: 5px;
  }

  .materialList-box {
    background: #f8f8f8;
    padding: 0.4rem;
  }

  .set-guid .list-content {
    padding: 0 0.4rem;
  }

  .set-guid .list-content p {
    text-align: left;
  }

  .loginHall-box {
    padding: 0.4rem;
    margin-top: 0.7rem;
    background: #f8f8f8;
  }

  .loginHall-box .tab .el-row:nth-child(2n + 1) .tab-content-tr {
    background: #eef8cf;
  }

  .loginHall-box .title-note {
    text-align: left;
    color: #6a6969;
  }

  .loginHall-box .tab {
    margin: 0;
  }

  .loginHall-box .copy-title,
  .materialList-box .copy-title {
    padding-left: 0;
  }

  /*签证*/
  .materialList-box .subTitle,
  .materialList-box .content-box p {
    text-align: left;
    font-size: 0.25rem;
    color: #696969;
    line-height: 0.5rem;
  }

  .set-guid .code-box {
    width: 33.3%;
    margin: 1rem auto 0 auto;
  }

  .set-guid .code-box .code-txt p {
    text-align: center;
    color: #0fb7d1;
  }

  /*手机端头部导航*/

  .header-nav .main-h-nav {
    width: 33.333%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
  }

  .header-nav {
    position: fixed;
    top: 1.1rem;
    width: 100%;
    z-index: 999;
    border-top: 1px solid #10b7d1;
    border-bottom: 1px solid #10b7d1;
    background: #222728f5;
    padding: 0 20px;
    font-size: 0.35rem;
  }

  .header-nav .sub-h-nav {
    height: 1rem;
  }

  .header-nav .sub-h-nav div {
    height: 100%;
    line-height: 1rem;
    color: #c8c8c8;
  }

  .header-nav .sub-h-nav div:active {
    color: #10b7d1;
  }

  .header-nav .sub-h-nav div:hover {
    color: #10b7d1;
  }

  .main-h-nav .el-submenu__title {
    height: 1rem;
    background: none !important;
  }

  .main-h-nav .el-submenu__title span {
    line-height: 1rem;
    display: inherit;
  }

  .el-submenu__title:focus,
  .el-submenu__title:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  .el-menu-item-group__title {
    display: none;
  }

  .el-menu-item-group ul {
    background: rgba(0, 0, 0, 0.7);
  }

  .el-menu-item:focus,
  .el-menu-item {
    background: none !important;
  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    color: #10b7d1 !important;
  }

  /*修改版*/
  .main-h-nav1 .menu {
    height: 1rem;
    line-height: 1rem;
    color: #c8c8c8;
    text-align: right;
  }

  .main-h-nav1 .menu span {
    padding-left: 0.3rem;
  }

  .main-h-nav1 .menu i {
    transition: transform 0.3s;
  }

  .main-h-nav1 .menu .is-open {
    transform: rotate(90deg);
  }

  .main-h-nav1 .menu:hover {
    color: #10b7d1;
  }

  .main-h-nav1 .menu-list {
    position: absolute;
    right: -20px;
    width: 56%;
    height: 50rem;
    background: #222728f5;
    color: #c8c8c8;
    text-align: right;
    border-top: 1px solid #10b7d1;
  }

  .main-h-nav1 .menu-list ul {
    margin-top: 0.8rem;
  }

  .main-h-nav1 .menu-list .menu-item {
    line-height: 1.5rem;
    text-align: right;
    padding-right: 20px;
  }

  .main-h-nav1 .menu-list .menu-item:hover {
    color: #10b7d1;
  }

  .main-h-nav1 .menu-list .back-btn {
    display: inline-block;
    margin-right: 20px;
    padding: 0.15rem 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #c8c8c8;
  }

  .main-h-nav1 .menu-list .back-btn span {
    padding-left: 5px;
  }

  .menu-fade-enter-active {
    animation: fadeInRightBig 0.5s;
  }

  .menu-fade-leave-active {
    animation: fadeInRightBig 0.5s reverse;
  }

  .el-tabs__content .el-tabs__nav-wrap .el-tabs__item.is-active {
    color: #0fb7d1;
  }

  .top-img .ec-top-foot {
    position: absolute;
    bottom: 5px;
    left: 16%;
    background: rgba(0, 182, 205, 1);
    min-width: 6.5rem;
    height: 0.78rem;
    align-items: center;
  }

  .top-img .ec-top-foot .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 10px;
  }

  @keyframes fadeInRightBig {
    from {
      opacity: 0;
      -webkit-transform: translate3d(2000px, 0, 0);
      transform: translate3d(2000px, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @media screen and (min-width: 990px) {
    .item-header-s .el-row {
      display: flex;
      justify-content: center;
    }

    .item-header-s .el-row .el-col:not(:first-child) {
      margin-left: 4.166667%;
    }

    .hidden-md-and-u {
      display: none;
    }
  }

  @media screen and (max-width: 990px) {
    .hidden-md-and-d {
      display: none;
    }

    .top-img .ec-top-foot {
      display: none;
    }

    .title-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-header .item-header-s .span {
      margin-bottom: 10px;
    }

    .item-header .item-header-text {
      padding: 20px 0 0 0 !important;
    }

    .tab {
      padding: 0;
    }

    .policy-centent-box {
      display: inline-block;
    }

    .policy-centent-box .code-box .code {
      position: static;
      width: 50%;
      margin: 20px auto 0 auto;
    }

    .policy-centent-box .code-box .code-txt {
      position: static;
    }

    .el-tabs--right .el-tabs__header.is-right,
    .downn,
    .nav {
      display: none;
    }

    .copyright-info .copyright-info-t {
      float: none;
      width: 100%;
      margin-left: 0;
    }

    .copyinfo-info-code {
      float: none;
      margin-top: 0.5rem;
      width: 100%;
    }
    .copyright-info .copyinfo-info-code img {
      width: unset;
    }
    .mii #tab-first:before,
    .mii #tab-second:before {
      display: none !important;
    }

    .mii #tab-first .tit::before,
    .mii #tab-second .tit::before {
      /* display: ; */
    }

    .mii #tab-first,
    .mii #tab-second {
      text-align: left !important;
      text-indent: 0 !important;
      line-height: 2rem;
      padding-bottom: 38px !important;
    }

    .mii #pane-1 .tit-name,
    .mii #pane-2 .tit-name {
      font-size: 0.1rem;
      top: 0;
      left: 0;
      display: block;
      white-space: normal;
      word-break: break-word;
      line-height: initial;
      text-indent: 0;
      padding: 0px 0 0 1.58rem !important;
      margin: -40px 0 0 0 !important;
      text-align: left;
      /* display: none !important; */
    }

    .mii #pane-2 #tab-first .tit-name {
      padding: 0px 0 0 1.58rem !important;
    }

    .mii #tab-first .tit,
    .mii #tab-second .tit {
      display: inline-block !important;
      padding-bottom: 0 !important;
      margin: 0 0 0 0.5rem;
      font-size: 0.4rem !important;
    }

    .mii #pane-1 .tit::before,
    .mii #pane-2 .tit::before {
      display: inline-block !important;
    }

    .el-tabs__nav-wrap .el-tabs__nav {
      padding-bottom: 0 !important;
    }
    .mii .el-tabs__content #tab-first .tit::before,
    .mii .el-tabs__content #tab-second .tit::before {
      top: 0.33rem !important;
    }
  }

  @media screen and (max-width: 760px) {
    .el-collapse-item__arrow {
      right: 0;
    }

    .title-box .text {
      padding: 0 20px !important;
    }

    .el-submenu .el-menu {
      width: 200px;
      margin-left: -64px;
    }

    .mii #tab-first:before,
    .mii #tab-second:before {
      /* width: .5518rem !important;
              height: .5518rem !important;
              margin: -.3359rem 0 0 0 !important;
              left: .4rem !important; */
      display: none !important;
    }

    .mii #tab-first,
    .mii #tab-second {
      min-height: 2rem !important;
      text-align: center !important;
      font-size: 0.42rem;
      padding-bottom: 0 !important;
    }

    .mii #pane-1 .tit-name,
    .mii #pane-2 .tit-name {
      display: none !important;
    }

    .mii #tab-first .tit,
    .mii #tab-second .tit {
      padding-top: 0.2rem;
      display: inline-block !important;
      margin: 0;
    }

    .mii #pane-1 .tit::before,
    .mii #pane-2 .tit::before {
      display: inline-block !important;
    }

    .el-tabs__nav-wrap .el-tabs__nav {
      padding-bottom: 0 !important;
    }
    .mii .el-tabs__content #tab-first .tit::before,
    .mii .el-tabs__content #tab-second .tit::before {
      top: 0.2rem !important;
    }
  }
  .el-collapse-item__wrap {
    border-bottom-width: 0;
  }

  .mii #tab-first,
  .mii #tab-second {
    font-size: 0.3rem;
    position: relative;
    /* min-height: 2rem; */
    text-align: left;
    text-indent: 1.2rem;
    height: 100%;
    padding-bottom: 12px;
  }

  .mii #pane-1 #tab-first:before {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon1.png) no-repeat center 0/100% 100%;
    transform: translate(0, -25%);
    top: 50%;
    left: 0.35rem;
  }

  .mii #pane-1 #tab-second:before {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon2.png) no-repeat center 0/100% 100%;
    transform: translate(0, -25%);
    top: 50%;
    left: 0.35rem;
  }

  .mii #pane-2 #tab-first:before {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon3.png) no-repeat center 0/100% 100%;
    transform: translate(0, -25%);
    top: 50%;
    left: 0.35rem;
  }

  .mii #pane-2 #tab-second:before {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon4.png) no-repeat center 0/100% 100%;
    transform: translate(0, -25%);
    top: 50%;
    left: 0.35rem;
  }

  .mii #pane-1 #tab-first.is-active:before,
  .mii .el-tabs__content #pane-1 #tab-first.is-active .tit::before {
    background: url(../../assets/images/D/tab-icon1-act.png) no-repeat center 0/100% 100% !important;
  }

  .mii #pane-1 #tab-second.is-active:before,
  .mii .el-tabs__content #pane-1 #tab-second.is-active .tit::before {
    background: url(../../assets/images/D/tab-icon2-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 #tab-first.is-active:before,
  .mii .el-tabs__content #pane-2 #tab-first.is-active .tit::before {
    background: url(../../assets/images/D/tab-icon3-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 #tab-second.is-active:before,
  .mii .el-tabs__content #pane-2 #tab-second.is-active .tit::before {
    background: url(../../assets/images/D/tab-icon4-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-1 .mian-left-l #tab-first.el-tabs__item:hover::before,
  .mii #pane-1 .mian-left-l #tab-first:hover .tit::before {
    background: url(../../assets/images/D/tab-icon1-hover.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-1 .mian-left-l #tab-second.el-tabs__item:hover::before,
  .mii #pane-1 .mian-left-l #tab-second:hover .tit::before {
    background: url(../../assets/images/D/tab-icon2-hover.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 .mian-left-l #tab-first.el-tabs__item:hover::before,
  .mii #pane-2 .mian-left-l #tab-first:hover .tit::before {
    background: url(../../assets/images/D/tab-icon3-hover.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 .mian-left-l #tab-second.el-tabs__item:hover::before,
  .mii #pane-2 .mian-left-l #tab-second:hover .tit::before {
    background: url(../../assets/images/D/tab-icon4-hover.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-1 .mian-left-l #tab-first.el-tabs__item.is-active:hover::before,
  .mii .el-tabs__content #pane-1 .mian-left-l #tab-first.is-active:hover .tit::before {
    background: url(../../assets/images/D/tab-icon1-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-1 .mian-left-l #tab-second.el-tabs__item.is-active:hover::before,
  .mii .el-tabs__content #pane-1 .mian-left-l #tab-second.is-active:hover .tit::before {
    background: url(../../assets/images/D/tab-icon2-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 .mian-left-l #tab-first.el-tabs__item.is-active:hover::before,
  .mii .el-tabs__content #pane-2 .mian-left-l #tab-first.is-active:hover .tit::before {
    background: url(../../assets/images/D/tab-icon3-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-2 .mian-left-l #tab-second.el-tabs__item.is-active:hover::before,
  .mii .el-tabs__content #pane-2 .mian-left-l #tab-second.is-active:hover .tit::before {
    background: url(../../assets/images/D/tab-icon4-act.png) no-repeat center 0/100% 100%;
  }

  .mii #pane-1 .tit-name,
  .mii #pane-2 .tit-name {
    /* position: absolute; */
    font-size: 0.1rem;
    top: 0;
    left: 0;
    display: block;
    white-space: normal;
    word-break: break-word;
    line-height: initial;
    text-indent: 0;
    padding: 0px 0 0 65px;
    margin: -30px 0 0 0;
    /* max-height: 1rem; */
  }

  .mii #pane-2 #tab-first .tit-name {
    left: 0;
  }

  .mii .el-tabs__content #tab-first .tit,
  .mii .el-tabs__content #tab-second .tit {
    display: block;
  }

  .mii .el-tabs__content #pane-1 #tab-first .tit::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon1.png) no-repeat center 0/100% 100%;
    margin: 0 0.4rem 0 0;
    top: 0.27rem;
    display: none;
  }

  .mii .el-tabs__content #pane-1 #tab-second .tit::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon2.png) no-repeat center 0/100% 100%;
    margin: 0 0.4rem 0 0;
    top: 0.27rem;
    display: none;
  }

  .mii .el-tabs__content #pane-2 #tab-first .tit::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon3.png) no-repeat center 0/100% 100%;
    margin: 0 0.4rem 0 0;
    top: 0.27rem;
    display: none;
  }

  .mii .el-tabs__content #pane-2 #tab-second .tit::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url(../../assets/images/D/tab-icon4.png) no-repeat center 0/100% 100%;
    margin: 0 0.4rem 0 0;
    top: 0.27rem;
    display: none;
  }

  .mii .el-tabs__content .el-tabs__nav.is-top {
    display: flex;
  }

  .mii .item-header {
    margin-top: 27px;
  }
}

</style>
<style lang="less">
.cityD {
  .cnts-item {
    text-align: left;
    &.i-one {
      h1 {
        color: #6a6969;
        font-size: 0.3rem;
      }
      i,em {
        color: #0fb7d1;
        font-size: 0.4rem;
        font-style: initial;
        display: block;
        position: relative;
        padding-left: 0.3rem;
      }
      > ul {
        > li {
          padding: 1.4888rem 0 0 0.4rem;
          text-align: justify;
          line-height: 1.75;
          > div {
            i,em {
              color: #0fb7d1;
              font-size: 0.4rem;
              font-style: initial;
              display: block;
              position: relative;
              span {
                position: absolute;
                color: #f1f1f1;
                font-size: 1.5518rem;
                bottom: 0;
                left: 0;
                line-height: 0.9;
                z-index: -1;
              }
            }
            p {
              color: #464646;
              display: block;
              font-size: 0.25rem;
            }
            > .one-left-row {
              margin-top: 0.4rem;
              padding-bottom: 1rem;
              > .el-col {
                padding-right: 2%;
                position: relative;
                &:nth-child(2n) {
                  > div {
                    background: #a5cd39;
                  }
                }
                > div {
                  position: relative;
                  background: #0fb7d1;
                  padding: 0.8rem 0.3rem 0.3rem;
                  line-height: initial;
                  height: 100%;
                  &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-width: 0.3rem;
                    border-color: #fff transparent transparent transparent;
                    border-style: solid;
                    z-index: 1;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                  }
                  img {
                    display: block;
                    margin: 0 auto 0.3rem;
                    width: 100%;
                  }
                  h3 {
                    color: #fff;
                    font-size: 0.4rem;
                    display: block;
                  }
                  p {
                    color: #fff;
                    font-size: 0.25rem;
                  }
                }
              }
            }
          }
        }
      }
      .one-left-row2 {
        padding-left: 0.3rem;
        font-size: 0.25rem;
        .left {
          ul {
            padding-bottom: 0.2rem;
            li {
              background: #cff1f6;
              padding: 0.1rem 0 0.1rem 0.2rem;
              &:nth-child(2n) {
                background: #f6f6f6;
              }
            }
          }
          p {
            padding-left: 0.2rem;
          }
        }
        .right {
          padding-left: 1rem;
          p {
          }
          img {
            width: 100%;
          }
        }
      }
    }
    &.i-three3 {
      .cnts1,
      .cnts2 {
        > h1 {
          font-size: 0.4rem;
          color: #0fb7d1;
        }
        > p {
          font-size: 0.25rem;
          color: #6a6969;
        }
        > div {
          padding-bottom: 0.3rem;
          > h3 {
            font-size: 0.4rem;
            color: #6a6969;
          }
          > p {
            font-size: 0.25rem;
            color: #6a6969;
            padding-left: 0.4rem;
          }
          &:last-child,
          &:first-child {
            > p {
              padding-left: 0;
            }
          }
        }
      }
      .cnts2 {
        background: #f8f8f8;
        padding: 0.7rem 0.4rem 0;
        margin: 0 -0.4rem 0;
      }
    }
    &.i-four {
      ul {
        li {
          .item-txt-a {
            .item-txt-b {
              text-align: center;
            }
          }
          .item-txt-box {
            display: flex;
            &::after {
              content: '';
              display: block;
              clear: both;
            }
          }
          .item-txt-answer {
            padding-left: 0.2rem;
            .cnts {
              display: inline-block;
              text-align: center;
              img {
                width: auto;
              }
              p {
                color: #6a6969;
                font-size: 0.25rem;
              }
            }
          }
          .item-txt-question {
            height: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    &.i-five {
      > div {
        padding-top: 0.4rem;
        h1 {
          color: #0fb7d1;
          font-size: 0.4rem;
        }
        p {
          color: #6a6969;
          font-size: 0.25rem;
        }
        &.cnt2 {
          background: #f8f8f8;
          padding: 0.4rem 0.4rem;
        }
        ul {
          li {
            .item-txt-a {
              margin-left: 0;
              .item-txt-b {
                text-align: center;
              }
            }
            .item-txt-box {
              margin-left: 0;
              .item-txt-question {
                text-align: center;
              }
            }
          }
        }
      }
    }
    &.i-six {
      > div {
        padding-top: 0.4rem;
        h1 {
          color: #0fb7d1;
          font-size: 0.4rem;
          span {
            font-size: 0.25rem;
          }
        }
        p {
          color: #6a6969;
          font-size: 0.25rem;
        }
        &.cnt2 {
          display: flex;
          align-items: center;
          padding: 0.4rem 0.4rem 0;
          img {
            width: auto;
          }
          p {
            color: #6a6969;
            font-size: 0.25rem;
          }
          .right {
            padding-left: 0.4rem;
          }
        }
        ul {
          li {
            .item-txt-a {
              margin-left: 0;
              .item-txt-b {
                text-align: center;
              }
            }
            .item-txt-box {
              margin-left: 0;
              .item-txt-question {
                text-align: center;
              }
            }
          }
        }
      }
    }
    &.i-seven {
      h1 {
        font-size: 0.4rem;
        color: #0fb7d1;
        padding-top: 0.4rem;
      }
      .tab {
        text-align: center;
        .tab-content-td {
          flex-wrap: wrap;
          .box {
            display: flex;
            width: 100%;
            padding-top: 0.2rem;
            .left {
              white-space: nowrap;
            }
            img {
              width: auto;
              display: block;
              margin: 0 auto;
            }
            p {
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
    }
    &.i-eit2 {
      p {
        font-size: 0.25rem;
      }
      .cnts2 {
        background: #f8f8f8;
        padding: 0.4rem;
        margin: 0.4rem 0 0 0;
      }
    }
    &.i-eit3 {
      img {
        width: auto;
        display: block;
        margin: 0.6rem auto;
      }
      > div {
        padding-bottom: 0.4rem;
      }
      p {
        font-size: 0.25rem;
        color: #696969;
        line-height: 2;
      }
      i,em {
        display: block;
        font-size: 0.25rem;
        color: #0fb7d1;
        line-height: 2;
        font-style: initial;
        text-align: left;
      }
      h1 {
        font-size: 0.4rem;
        color: #0fb7d1;
        span {
          font-size: 0.25rem;
          color: #6a6969;
        }
      }
      .cnts2 {
        background: #f8f8f8;
        padding: 0.4rem;
        margin: 0.4rem 0 0.4rem 0;
      }
      .tab {
        text-align: center;
        margin: 0;
      }
      ul {
        li {
          .item-txt-a {
            margin-left: 0;
            height: auto;
            display: flex;
            .item-txt-b {
              text-align: center;
              height: 100%;
              display: flex;
              justify-content: center;
            }
            .item-txt-c {
              height: auto;
              /*padding-left: .28rem;*/
            }
          }
          .item-txt-box {
            margin-left: 0;
            .item-txt-question {
              text-align: center;
            }
            .cnts {
              line-height: 0.7rem;
            }
          }
        }
      }
    }
    &.i-night2 {
      > div {
        padding-top: 0.4rem;
        h1 {
          font-size: 0.4rem;
          color: #0fb7d1;
          span {
            font-size: 0.25rem;
            color: #6a6969;
          }
        }
        p {
          font-size: 0.25rem;
          color: #696969;
          line-height: 2;
        }
        i,em {
          display: block;
          font-size: 0.25rem;
          color: #696969;
          line-height: 2;
          font-style: initial;
          text-indent: 1.5em;
        }
        img {
          width: auto;
          display: block;
          margin: 0.3703rem auto;
          max-width: 100%;
        }
      }
    }
    &.i-night3 {
      > div {
        h1 {
          font-size: 0.4rem;
          color: #0fb7d1;
          span {
            font-size: 0.25rem;
            color: #6a6969;
          }
        }
        ul {
          li {
            .item-txt-answer {
              .cnts {
                text-align: center;
                display: inline-block;
                img {
                  width: auto;
                }
                p {
                  font-size: 0.25rem;
                  color: #6a6969;
                }
              }
            }
          }
        }
      }
    }
  }
  .txt-content {
    /*padding-left: 0.4rem;*/
    text-align: left;
    p {
      height: unset;
      font-size: 0.3rem;
    }
    .number-list {
      margin-top: 0.74rem;
      padding-left: 0.4rem;
      h1 {
        font-size: 0.3rem;
      }
      p {
        height: unset;
        font-size: 0.3rem;
        margin-left: 0.2rem;
        margin-top: 0.1rem;
      }
    }
  }
  .copy-introduce {
    margin-top: 1.3rem;
    .copy-statute-link {
      display: flex;
      flex-wrap: wrap;
      .left {
        margin-right: 0.2rem;
        position: relative;
        color: #00b6cd;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 40%;
          border-top: 3px solid #00b6cd;
        }
      }
      .right {
        margin-left: 0.2rem;
        position: relative;
        color: #a5cd39;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 40%;
          border-top: 3px solid #a5cd39;
        }
      }
      .link-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        border: 1px solid #d8d8d8;
        padding: 1.5rem 0.6rem 1.8rem 0.6rem;
        text-align: left;
        p {
          height: auto;
        }
        .txt-box {
          font-size: 0.4444rem;
          align-self: self-start;
          flex: 1;
        }
        .link-icon {
          margin-top: 0.1rem;
          align-self: self-end;
        }
      }
      a {
        /*display: ;*/
        color: unset;
      }
    }
    .flow-img {
      width: 100%;
    }
    img {
      width: unset;
    }
  }
  .question {
    padding-top: 0.5rem;
  }
  .D2-02-02 {
    text-align: left;
    .top-info-box {
      margin: 0.8rem 0;
      p {
        font-size: 0.3rem;
        color: #6a6969;
        word-break: break-word;
      }
    }
    .eg-box {
      .eg-txt-content {
        .number-list {
          h1 {
            font-size: 0.3rem;
            padding-left: 0.4rem;
            &:not(:first-child) {
              margin-top: 0.5rem;
            }
          }
          h2 {
            font-size: 0.3rem;
            padding-left: 0.55rem;
            padding-bottom: 0;
            position: inherit;
            margin-top: 0.2rem;
          }
          p {
            font-size: 0.3rem;
            padding-left: 0.55rem;
            margin-top: 0.1rem;
          }
        }
      }
    }
    .flowChart-box {
      margin-top: 1rem;
      .box-img {
        margin-top: 0.5rem;
      }
    }
  }
  @media screen and (max-width: 990px) {
    .item-header .item-header-s .span {
      font-size: 0.35rem;
    }
    .item-header .item-header-text {
      font-size: 0.35rem;
    }
    .copyright-info .copyright-info-t {
      font-size: 0.35rem;
    }
    .copy-title {
      font-size: 0.45rem;
    }
    .txt-content p {
      font-size: 0.35rem;
    }
    .txt-content .number-list h1 {
      font-size: 0.35rem;
    }
    .txt-content .number-list p {
      font-size: 0.35rem;
    }
    .item-txt-c {
      font-size: 0.35rem;
    }
    .item-txt-answer {
      font-size: 0.32rem;
    }
    .D2-02-02 .top-info-box p {
      font-size: 0.35rem;
    }
    .D2-02-02 .eg-box .eg-txt-content .number-list h1 {
      font-size: 0.35rem;
    }
    .D2-02-02 .eg-box .eg-txt-content .number-list h2 {
      font-size: 0.35rem;
    }
    .D2-02-02 .eg-box .eg-txt-content .number-list p {
      font-size: 0.35rem;
    }
    .item-txt-t {
      font-size: 0.35rem;
    }
    .item-txt-m {
      font-size: 0.25rem;
    }
    .tab .tab-content-td {
      padding: 0.4rem 0.2rem;
    }
    .circuit-content {
      width: 90%;
    }
    .set-guid .copy-title span {
      font-size: 0.3rem;
    }
    .mii .title-box .text {
      font-size: 0.35rem;
    }
    .circuit-content p {
      font-size: 0.35rem;
    }
    .materialList-box .subTitle,
    .materialList-box .content-box p {
      font-size: 0.3rem;
    }
  }
  @media screen and (max-width: 776px) {
    .copy-introduce .copy-statute-link {
      .left {
        margin-right: 0;
      }
      .right {
        margin-left: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
@media screen and (max-width: 990px) {
  .cityD {
    .cnts-item {
      &.i-one {
        h1 {
          font-size: 0.4444rem;
        }
        > ul {
          > li {
            padding-left: 0;
            padding-top: 0.6rem;
            > div {
              > i {
                font-size: 0.4444rem;
              }
              > p {
                font-size: 0.3703rem;
              }
              > .one-left-row {
                > .el-col {
                  padding-right: 0;
                  margin-bottom: 0.4rem;
                  > div {
                    padding: 0.3rem;
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    &::before {
                      display: none;
                    }
                    .left {
                      width: 40%;
                      padding-left: 0.4rem;
                      img {
                        margin-bottom: 0;
                      }
                    }
                    .right {
                      width: 60%;
                      h3 {
                        font-size: 0.4444rem;
                      }
                      p {
                        padding-top: 0.4rem;
                        font-size: 0.3703rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .h-1 {
          margin: 0 -0.4rem 0;
        }
        .i-1 {
          padding-top: 0.3rem !important;
        }
        .one-left-row2 {
          padding-left: 0;
          .left {
            ul {
              li {
                font-size: 0.3333rem;
              }
            }
            p {
              font-size: 0.3333rem;
            }
          }
          .right {
            padding: 0;
            display: flex;
            flex-direction: column-reverse;
            img {
              width: 50%;
              display: block;
              margin: 0.2rem auto 0.2rem;
            }
            p {
              font-size: 0.3333rem;
              padding: 0 !important;
              text-align: center;
            }
          }
        }
        i {
          font-size: 0.4444rem;
          padding-left: 0 !important;
        }
      }
      &.i-three3 {
        .cnts1 {
          > h1 {
            font-size: 0.4444rem;
          }
          > div {
            > p {
              font-size: 0.3703rem;
            }
          }
        }
        .cnts2 {
          > h1 {
            font-size: 0.4444rem;
          }
          > div {
            > p {
              font-size: 0.3703rem;
            }
          }
        }
      }
      &.i-four {
        ul {
          li {
            .item-txt-a {
              margin-left: 0;
              height: auto;
              .item-txt-b {
                position: absolute;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .item-txt-c {
                height: auto;
                font-size: 0.3703rem;
              }
            }
            .item-txt-box {
              margin: 0.2rem 0 0;
            }
            .item-txt-answer {
              .cnts {
                p {
                  font-size: 0.3703rem;
                }
              }
            }
          }
        }
      }
      &.i-five {
        > div {
          p {
            font-size: 0.3703rem;
          }
        }
      }
      &.i-six {
        > div {
          p {
            font-size: 0.3703rem;
          }
          h1 {
            span {
              font-size: 0.3703rem;
            }
          }
          &.cnt2 {
            flex-direction: column;
            .left {
              width: 100%;
              img {
                display: block;
                margin: 0 auto;
                width: 60%;
              }
            }
            .right {
              width: 100%;
              padding-left: 0;
              padding-top: 0.6rem;
              p {
                font-size: 0.3703rem;
                text-align: center;
              }
            }
          }
        }
      }
      &.i-seven {
        word-break: break-word;
        .tab {
          .tab-content-td {
            .box {
              img {
                width: 60%;
              }
            }
          }
        }
      }
      &.i-eit2 {
        p {
          font-size: 0.3703rem;
        }
      }
      &.i-eit3 {
        p {
          font-size: 0.3703rem;
        }
        i {
          font-size: 0.3703rem;
        }
        img {
          width: 100%;
        }
      }
      &.i-night2 {
        > div {
          h1 {
            font-size: 0.4444rem;
          }
          p {
            font-size: 0.3703rem;
          }
          i {
            font-size: 0.3703rem;
            text-indent: 1.6em;
          }
        }
      }
      &.i-night3 {
        > div ul li {
          .item-txt-answer {
            padding-left: 0;
            text-align: center;
            .cnts {
              display: block;
              p {
                font-size: 0.3703rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
